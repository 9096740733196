import {put, select, take, fork} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import {apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';

const loadACECommissioners = function* loadACECommissioners() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const commissioners = yield select(contractPartnerSelectors.getCommissioners);
    const {activeTab} = yield* selectSearchQueryParams();

    if (Object.keys(commissioners).length !== 0 || activeTab !== contractPartnerScreenTabs.CASE_HISTORY) {
        return;
    }

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_ACE_COMMISSIONERS_REQUEST,
        partnerManagementService.getACEPartners, {
            partnerType: apmACEPartnerTypes.COMMISSIONER,
        },
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_ACE_COMMISSIONERS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.FETCH_ACE_COMMISSIONERS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {acePartnerDTOs} = response;

        yield put({
            type: contractPartnerActionTypes.STORE_ACE_COMMISSIONERS,
            payload: {commissionerDTOs: acePartnerDTOs},
        });
    }
};

export default loadACECommissioners;
