import {put, select, take, fork} from 'redux-saga/effects';
import {apmACEPartnerTypes, apmACEPartnerServiceTypes, apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';

const loadEmergencyLawyers = function* loadEmergencyLawyers({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {location} = payload;

    if (location.query.modal && location.state?.isModalOpen) return;

    yield fork(
        fetchRequest,
        serviceProviderActionTypes.FETCH_EMERGENCY_LAWYERS_REQUEST,
        partnerManagementService.getACEPartners, {
            partnerType: apmACEPartnerTypes.SERVICE_PROVIDER,
            serviceType: apmACEPartnerServiceTypes.LEGAL_ADVICE,
            contractStatus: apmContractPartnerContractStatusTypes.ACTIVE,
        },
    );

    const responseAction = yield take([
        serviceProviderActionTypes.FETCH_EMERGENCY_LAWYERS_REQUEST_SUCCEEDED,
        serviceProviderActionTypes.FETCH_EMERGENCY_LAWYERS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {acePartnerDTOs} = response;

        yield put({
            type: serviceProviderActionTypes.STORE_EMERGENCY_LAWYERS,
            payload: {emergencyLawyerDTOs: acePartnerDTOs},
        });
    }
};

export default loadEmergencyLawyers;
