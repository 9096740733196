import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';
import modalIds from '../../modalIds';

const loadCPQMFeedbackSearchResults = function* loadCPQMFeedbackSearchResults({payload}) {
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== contractPartnerScreenTabs.QUALITY_MANAGEMENT) return;

    const {location, match} = payload;
    const searchQueryParams = new URLSearchParams(location.search);
    if (!searchQueryParams.get('sort')) searchQueryParams.append('sort', 'createdAt,desc');
    const {contractPartnerId} = match?.params;

    // do not reload qm feedback search results when agent opens a modal
    if (location && location.state?.isModalOpen
        && [modalIds.CP_CREATE_QUALITY_REPORT, modalIds.QM_NOTE_QUALIFICATION].includes(location.state?.modalId)
    ) return;

    yield put({
        type: contractPartnerActionTypes.SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS,
        payload: {searchQueryParams, contractPartnerId},
    });
};

export default loadCPQMFeedbackSearchResults;
