import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates, RentalCarPricing, SPPricing} from '@ace-de/eua-entity-types';
import {useStyles, Modal, ButtonPrimary} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, closeIcon} from '@ace-de/ui-components/icons';
import {Form, NumberInputField, InputField} from '@ace-de/ui-components/form';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';
import * as serviceProviderSelectors from '../serviceProviderSelectors';

const fieldsToDailyFromMapping = {
    'oneDayPrice': 1,
    'threeDaysPrice': 3,
    'fiveDaysPrice': 5,
    'sixDaysPrice': 6,
    'sevenDaysPrice': 7,
    'eightDaysPrice': 8,
    'tariffPrice': 0,
};

const createPricingDTO = (field, data) => {
    if (!Object.keys(fieldsToDailyFromMapping).includes(field)) return null;
    return new SPPricing().fromDTO({
        ...(!!fieldsToDailyFromMapping[field] && {dailyFrom: fieldsToDailyFromMapping[field]}),
        ...(data[field] && typeof parseFloat(data[field]) === 'number' ? {price: data[field]} : {price: null}),
        ...(!fieldsToDailyFromMapping[field] && {isFlatPrice: true}),
    });
};

const formatPricingArray = data => {
    return Object.keys(data)
        .map(field => createPricingDTO(field, data))
        .filter(pricing => !!pricing && pricing?.price !== null);
};

const initialErrorsState = {
    sippCode: '',
    oneDayPrice: '',
    threeDaysPrice: '',
    fiveDaysPrice: '',
    sixDaysPrice: '',
    sevenDaysPrice: '',
    tariffPrice: '',
    eightDaysPrice: '',
};

const SPRentalCarPricingDataModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale} = useTranslate();
    const translateModal = createTranslateShorthand('sp_rental_car_pricing_data_modal');
    const {hasBackdrop, serviceProvider} = props;
    const {confirmRentalCarPricingCreation, declineRentalCarPricingCreation, rentalCarPricing} = props;
    const {confirmRentalCarPricingUpdate, declineRentalCarPricingUpdate} = props;
    const [errors, setErrors] = useState(initialErrorsState);

    const pricingData = useMemo(() => (rentalCarPricing
        ? {
            ...rentalCarPricing,
            oneDayPrice: rentalCarPricing.pricing?.find(pricing => pricing.dailyFrom === 1)?.price || '',
            threeDaysPrice: rentalCarPricing.pricing?.find(pricing => pricing.dailyFrom === 3)?.price || '',
            fiveDaysPrice: rentalCarPricing.pricing?.find(pricing => pricing.dailyFrom === 5)?.price || '',
            sixDaysPrice: rentalCarPricing.pricing?.find(pricing => pricing.dailyFrom === 6)?.price || '',
            sevenDaysPrice: rentalCarPricing.pricing?.find(pricing => pricing.dailyFrom === 7)?.price || '',
            eightDaysPrice: rentalCarPricing.pricing?.find(pricing => pricing.dailyFrom === 8)?.price || '',
            tariffPrice: rentalCarPricing.pricing?.find(pricing => pricing.isFlatPrice)?.price || '',
        }
        : new RentalCarPricing()
    ), [rentalCarPricing]);

    const validateFormValues = formValues => {
        let hasError = false;
        let updatedErrors = {};
        // validate mandatory fields
        if (!formValues['sippCode'] || !formValues['oneDayPrice']) {
            updatedErrors = {
                ...updatedErrors,
                ...(!formValues['sippCode'] && {sippCode: translateModal('error_message.field_is_required')}),
                ...(!formValues['oneDayPrice'] && {oneDayPrice: translateModal('error_message.field_is_required')}),
            };
            hasError = true;
        }

        // validate SIPP code format
        const sippCodeRegex = /^[a-zA-Z]{1,4}$/;
        if (!sippCodeRegex.test(formValues['sippCode'])) {
            updatedErrors['sippCode'] = translateModal('error_message.invalid_sipp_code_format');
            hasError = true;
        }

        // check if SIPP code already exists
        if (serviceProvider?.rentalCarPricing?.find(pricing => {
            return pricing.sippCode?.toUpperCase() === formValues['sippCode']?.toUpperCase();
        })) {
            if (rentalCarPricing?.sippCode?.toUpperCase() !== formValues['sippCode']?.toUpperCase()) {
                updatedErrors['sippCode'] = translateModal('error_message.sipp_code_already_exists');
                hasError = true;
            }
        }

        // validate price range
        [
            'oneDayPrice',
            'threeDaysPrice',
            'fiveDaysPrice',
            'sixDaysPrice',
            'sevenDaysPrice',
            'eightDaysPrice',
            'tariffPrice',
        ].forEach(field => {
            if (formValues[field] && typeof parseFloat(formValues[field]) === 'number'
                && !(parseFloat(formValues[field]) >= 0 && parseFloat(formValues[field]) <= 999.99)) {
                updatedErrors[field] = translateModal('error_message.invalid_price_range');
                hasError = true;
            }

            const twoDecimalsFloatRegex = /^\d+([.,]\d{1,2})?$/;
            if (formValues[field] && typeof parseFloat(formValues[field]) === 'number'
                && !twoDecimalsFloatRegex.test(formValues[field])) {
                updatedErrors[field] = translateModal('error_message.invalid_price_format');
                hasError = true;
            }
        });
        if (hasError) {
            setErrors(prevState => ({
                ...prevState,
                ...updatedErrors,
            }));
        }

        if (!hasError) setErrors(initialErrorsState);
        return hasError;
    };

    const handleOnSubmit = formValues => {
        setErrors(initialErrorsState);
        const hasError = validateFormValues(formValues);
        if (hasError) return;

        const rentalCarPricingData = {
            partnerId: serviceProvider.id,
            sippCode: formValues.sippCode?.toUpperCase(),
            pricing: formatPricingArray(formValues),
        };

        if (rentalCarPricing) {
            confirmRentalCarPricingUpdate({
                rentalCarPricingData,
            });
            return;
        }
        confirmRentalCarPricingCreation({
            rentalCarPricingData,
        });
    };

    return (
        <Modal
            title={rentalCarPricing
                ? translateModal('title.edit_rental_car_pricing_data')
                : translateModal('title.create_rental_car_pricing_data')}
            action={(
                <Icon
                    icon={closeIcon}
                    onClick={rentalCarPricing ? declineRentalCarPricingUpdate : declineRentalCarPricingCreation}
                />
            )}
            hasBackdrop={hasBackdrop}
            contentClassName={cx('ace-c-modal__content--full-bleed')}
        >
            <div>
                <Form
                    name="createSPRentalCarPricingForm"
                    onSubmit={handleOnSubmit}
                    isDisabled={serviceProvider.persistenceState === persistenceStates.PENDING}
                >
                    {formValues => {
                        return (
                            <div className={cx('global!ace-u-margin--32-0')}>
                                <Table qaIdent="sp-rental-car-pricing-data">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                {translateModal('table_cell.sipp_code')}
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                {translateModal('table_cell.one_day_price')}
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                {translateModal('table_cell.three_days_price')}
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                {translateModal('table_cell.five_days_price')}
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                {translateModal('table_cell.six_days_price')}
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                {translateModal('table_cell.seven_days_price')}
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                {translateModal('table_cell.tariff_price')}
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                {translateModal('table_cell.eight_days_price')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <InputField
                                                    name="sippCode"
                                                    value={pricingData?.sippCode || ''}
                                                    className={cx('global!ace-u-width--128')}
                                                    errors={errors?.sippCode ? [errors.sippCode] : []}
                                                />
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <NumberInputField
                                                    name="oneDayPrice"
                                                    value={pricingData?.oneDayPrice || ''}
                                                    className={cx('global!ace-u-width--128')}
                                                    min={0}
                                                    locale={activeLocale}
                                                    errors={errors?.oneDayPrice ? [errors.oneDayPrice] : []}
                                                />
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <NumberInputField
                                                    name="threeDaysPrice"
                                                    value={pricingData?.threeDaysPrice || ''}
                                                    className={cx('global!ace-u-width--128')}
                                                    min={0}
                                                    locale={activeLocale}
                                                    errors={errors?.threeDaysPrice ? [errors.threeDaysPrice] : []}
                                                />
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <NumberInputField
                                                    name="fiveDaysPrice"
                                                    value={pricingData?.fiveDaysPrice || ''}
                                                    className={cx('global!ace-u-width--128')}
                                                    min={0}
                                                    locale={activeLocale}
                                                    errors={errors?.fiveDaysPrice ? [errors.fiveDaysPrice] : []}
                                                />
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <NumberInputField
                                                    name="sixDaysPrice"
                                                    value={pricingData?.sixDaysPrice || ''}
                                                    className={cx('global!ace-u-width--128')}
                                                    min={0}
                                                    locale={activeLocale}
                                                    errors={errors?.sixDaysPrice ? [errors.sixDaysPrice] : []}
                                                />
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <NumberInputField
                                                    name="sevenDaysPrice"
                                                    value={pricingData?.sevenDaysPrice || ''}
                                                    className={cx('global!ace-u-width--128')}
                                                    min={0}
                                                    locale={activeLocale}
                                                    errors={errors?.sevenDaysPrice ? [errors.sevenDaysPrice] : []}
                                                />
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <NumberInputField
                                                    name="tariffPrice"
                                                    value={pricingData?.tariffPrice || ''}
                                                    className={cx('global!ace-u-width--128')}
                                                    min={0}
                                                    locale={activeLocale}
                                                    errors={errors?.tariffPrice ? [errors.tariffPrice] : []}
                                                />
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <NumberInputField
                                                    name="eightDaysPrice"
                                                    value={pricingData?.eightDaysPrice || ''}
                                                    className={cx('global!ace-u-width--128')}
                                                    min={0}
                                                    locale={activeLocale}
                                                    errors={errors?.eightDaysPrice ? [errors.eightDaysPrice] : []}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--justify-flex-end',
                                    ])}
                                >
                                    <ButtonPrimary
                                        name="submitRentalCarPricing"
                                        type="submit"
                                        className={cx([
                                            'global!ace-u-margin--top-64',
                                            'global!ace-u-margin--right-32',
                                        ])}
                                    >
                                        {translateModal('button_label.save')}
                                    </ButtonPrimary>
                                </div>
                            </div>
                        );
                    }}
                </Form>
            </div>
        </Modal>
    );
};

SPRentalCarPricingDataModal.propTypes = {
    declineRentalCarPricingCreation: PropTypes.func.isRequired,
    confirmRentalCarPricingCreation: PropTypes.func.isRequired,
    declineRentalCarPricingUpdate: PropTypes.func.isRequired,
    confirmRentalCarPricingUpdate: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
    serviceProvider: PropTypes.object,
    rentalCarPricing: PropTypes.object,
};

SPRentalCarPricingDataModal.defaultProps = {
    hasBackdrop: true,
    serviceProvider: null,
    rentalCarPricing: null,
};

const mapStateToProps = (state, props) => {
    const serviceProviderSelector = serviceProviderSelectors.createServiceProviderSelector();
    const pricingDataSelector = serviceProviderSelectors.createSPRentalCarPricingSelector();

    return {
        serviceProvider: serviceProviderSelector(state, props),
        rentalCarPricing: pricingDataSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineRentalCarPricingCreation: () => dispatch({
        type: serviceProviderActionTypes.DECLINE_CREATE_SP_RENTAL_CAR_PRICING,
    }),
    confirmRentalCarPricingCreation: payload => dispatch({
        type: serviceProviderActionTypes.CONFIRM_CREATE_SP_RENTAL_CAR_PRICING,
        payload,
    }),
    declineRentalCarPricingUpdate: () => dispatch({
        type: serviceProviderActionTypes.DECLINE_UPDATE_SP_RENTAL_CAR_PRICING,
    }),
    confirmRentalCarPricingUpdate: payload => dispatch({
        type: serviceProviderActionTypes.CONFIRM_UPDATE_SP_RENTAL_CAR_PRICING,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SPRentalCarPricingDataModal);
