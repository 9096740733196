import {all, fork, takeLatest} from 'redux-saga/effects';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';
import contractPartnerAssignmentChannelsUpdateFlow from './sagas/contractPartnerAssignmentChannelsUpdateFlow';
import contractPartnerContactDetailsUpdateFlow from './sagas/contractPartnerContactDetailsUpdateFlow';
import contractPartnerStatusUpdateFlow from './sagas/contractPartnerStatusUpdateFlow';
import createCPContactDataFlow from './sagas/createCPContactDataFlow';
import createQualityManagementNoteQualificationFlow from './sagas/createQualityManagementNoteQualificationFlow';
import createQualityReportFlow from './sagas/createQualityReportFlow';
import createTemporaryRestrictionFlow from './sagas/createTemporaryRestrictionFlow';
import deleteCPContactDataFlow from './sagas/deleteCPContactDataFlow';
import downloadQualityManagementFeedbacks from './sagas/downloadQualityManagementFeedbacks';
import loadCPOperationAreaMapLayers from './sagas/loadCPOperationAreaMapLayers';
import searchContractPartners from './sagas/searchContractPartners';
import searchCPLocationGeolocation from './sagas/searchCPLocationGeolocation';
import searchCPQualityManagementFeedbacks from './sagas/searchCPQualityManagementFeedbacks';
import searchServiceCases from './sagas/searchServiceCases';
import submitQMNoteQualificationEmailForm from './sagas/submitQMNoteQualificationEmailForm';
import updateContractPartnerLocationFlow from './sagas/updateContractPartnerLocationFlow';
import updateContractPartnerServices from './sagas/updateContractPartnerServices';
import updateCPContactDataFlow from './sagas/updateCPContactDataFlow';
import updateTemporaryRestrictionFlow from './sagas/updateTemporaryRestrictionFlow';

/**
 *  Contract partner watcher saga
 */
const contractPartnerWatcher = function* contractPartnerWatcher() {
    yield all([
        takeLatest(contractPartnerActionTypes.SEARCH_CONTRACT_PARTNERS, searchContractPartners),
        fork(contractPartnerStatusUpdateFlow),
        fork(contractPartnerContactDetailsUpdateFlow),
        fork(updateContractPartnerLocationFlow),
        takeLatest(contractPartnerActionTypes.SEARCH_CP_LOCATION_GEOLOCATION, searchCPLocationGeolocation),
        fork(contractPartnerAssignmentChannelsUpdateFlow),
        fork(createTemporaryRestrictionFlow),
        fork(updateTemporaryRestrictionFlow),
        takeLatest(
            contractPartnerActionTypes.SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS,
            searchCPQualityManagementFeedbacks,
        ),
        fork(downloadQualityManagementFeedbacks),
        takeLatest(contractPartnerActionTypes.SEARCH_SERVICE_CASES, searchServiceCases),
        fork(createQualityReportFlow),
        fork(createQualityManagementNoteQualificationFlow),
        fork(submitQMNoteQualificationEmailForm),
        fork(updateContractPartnerServices),
        fork(createCPContactDataFlow),
        fork(updateCPContactDataFlow),
        fork(deleteCPContactDataFlow),
        fork(loadCPOperationAreaMapLayers),
    ]);
};

export default contractPartnerWatcher;
