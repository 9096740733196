import {take, select, put, fork, call} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {ACEPartner, apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

/**
 * Contract partner status update flow (uses modal)
 */
const contractPartnerStatusUpdateFlow = function* contractPartnerStatusUpdateFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_CONTRACT_PARTNER_STATUS_UPDATE_FLOW);
        const {contractPartnerId} = payload;

        yield* openModal(modalIds.CP_STATUS_UPDATE);

        const chosenModalOption = yield take([
            contractPartnerActionTypes.CONFIRM_CONTRACT_PARTNER_STATUS_UPDATE,
            contractPartnerActionTypes.DECLINE_CONTRACT_PARTNER_STATUS_UPDATE,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_CONTRACT_PARTNER_STATUS_UPDATE) {
            const {contractPartnerData} = chosenModalOption.payload;

            // delete scheduled status change (if exists)
            if (!contractPartnerData.scheduledAt) {
                yield call(
                    partnerManagementService.deleteScheduledContractPartnerStatusChange,
                    {
                        contractPartnerId,
                    },
                );
            }

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_STATUS_REQUEST,
                contractPartnerData.scheduledAt
                    ? partnerManagementService.scheduleContractPartnerStatusChange
                    : partnerManagementService.updateACEPartner,
                {
                    ...(contractPartnerData.scheduledAt && {
                        contractPartnerData,
                        contractPartnerId,
                    }),
                    ...(!contractPartnerData.scheduledAt && {
                        acePartnerData: {
                            ...ACEPartner.objectToPatchDTO(contractPartnerData),
                            partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
                        },
                        acePartnerId: contractPartnerId,
                    }),
                },
            );

            const updateContractPartnerStatusResponseAction = yield take([
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_FAILED,
            ]);

            if (!updateContractPartnerStatusResponseAction.error) {
                const {response} = updateContractPartnerStatusResponseAction.payload;
                const {acePartnerDTO} = response;

                if (acePartnerDTO) {
                    yield put({
                        type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                        payload: {contractPartnerDTOs: [acePartnerDTO]},
                    });
                }
            }
        }

        yield* closeModal(modalIds.CP_STATUS_UPDATE);
    }
};

export default contractPartnerStatusUpdateFlow;
