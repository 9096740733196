import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {camelCase, snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {apmTemporaryRestrictionCategoryTypes} from '@ace-de/eua-entity-types';
import {useStyles, Modal, Option, ButtonPrimary, DateField} from '@ace-de/ui-components';
import {Form, TextAreaField, SelectField, Checkbox, InputField} from '@ace-de/ui-components/form';
import {InteractiveIcon, calendarIcon, closeIcon} from '@ace-de/ui-components/icons';
import * as contractPartnersSelectors from '../contractPartnerSelectors';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import config from '../../config';

const nonMandatoryTemporaryRestrictionFields = ['submitTemporaryRestrictionFormData', 'restrictionEndDateTime'];

const CreateTemporaryRestrictionModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('create_temporary_restriction_modal');
    const {hasBackdrop, contractPartner} = props;
    const {declineCreateTemporaryRestriction, confirmCreateTemporaryRestriction} = props;
    const [temporaryRestrictionFormData, setTemporaryRestrictionFormData] = useState();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [hasDurationError, setHasDurationError] = useState(false);

    const handleOnSubmit = formValues => {
        if (formValues.restrictionStartDateTime !== '' && formValues.restrictionEndDateTime !== '') {
            const startDateTime = moment(formValues.restrictionStartDateTime);
            const endDateTime = moment(formValues.restrictionEndDateTime);
            if (endDateTime.diff(startDateTime, 'second') <= 0) {
                setHasDurationError(true);
                return;
            }

            if (hasDurationError) {
                setHasDurationError(false);
            }
        }

        confirmCreateTemporaryRestriction({
            temporaryRestrictionData: formValues,
        });
    };

    const isButtonDisabledCheck = formValues => {
        return Object.keys(formValues).filter(fieldName => {
            if (!fieldName) return false;
            if (nonMandatoryTemporaryRestrictionFields.includes(fieldName)) return false;
            switch (typeof formValues[fieldName]) {
                // if the mandatory field is an empty object/array, disable button
                case 'object': {
                    return formValues[fieldName]?.length === 0;
                }

                // if the mandatory checkbox is not checked, disable button
                case 'boolean': {
                    return !formValues[fieldName];
                }

                default: {
                    return !formValues[fieldName];
                }
            }
        })?.length > 0;
    };

    const handleOnChange = formValues => {
        if (!formValues) return;
        if (isButtonDisabledCheck(formValues) !== isButtonDisabled) {
            setIsButtonDisabled(!isButtonDisabled);
        }

        setTemporaryRestrictionFormData({
            ...formValues,
        });
    };

    return (
        <Modal
            title={translateModal('header.title')}
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineCreateTemporaryRestriction}
                />
            )}
            contentClassName={cx(['ace-c-modal__content--scrollable', 'global!ace-u-modal-content-size--m'])}
        >
            <p
                className={cx([
                    'global!ace-u-margin--top-24',
                    'global!ace-u-margin--bottom-8',
                    'global!ace-u-typography--variant-body-bold',
                    'global!ace-u-full-width',
                ])}
            >
                {translateModal('text.contract_partner')}
            </p>
            {!!contractPartner && (
                <p
                    className={cx([
                        'global!ace-u-typography--variant-body',
                        'global!ace-u-full-width',
                        'global!ace-u-margin--bottom-24',
                    ])}
                >
                    {contractPartner.name || ''}
                </p>
            )}
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-full-width',
                ])}
            >
                <Form
                    name="createTemporaryRestrictionForm"
                    onChange={handleOnChange}
                    onSubmit={handleOnSubmit}
                >
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--bottom-8',
                        ])}
                    >
                        {translateModal('text.reporting_person')}
                    </p>
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--bottom-16')}>
                        <InputField
                            name="reporterFirstName"
                            label={translateModal('input_label.reporter_first_name')}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={temporaryRestrictionFormData?.reporterFirstName || ''}
                        />
                        <InputField
                            name="reporterLastName"
                            label={translateModal('input_label.reporter_last_name')}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={temporaryRestrictionFormData?.reporterLastName || ''}
                        />
                        <InputField
                            name="reporterRole"
                            label={translateModal('input_label.reporter_role')}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={temporaryRestrictionFormData?.reporterRole || ''}

                        />
                    </div>
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-margin--bottom-8',
                        ])}
                    >
                        {translateModal('text.duration')}
                    </p>
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--bottom-32')}>
                        <DateField
                            name="restrictionStartDateTime"
                            label={translateModal('input_label.restriction_start_date_time')}
                            className={cx('global!ace-u-grid-column--span-6', 'global!ace-u-full-width')}
                            icon={calendarIcon}
                            value={temporaryRestrictionFormData?.restrictionStartDateTime || ''}
                            format="DD.MM.YYYY, HH:mm"
                            onInvalidDate={setIsButtonDisabled}
                        />
                        <DateField
                            name="restrictionEndDateTime"
                            label={translateModal('input_label.restriction_end_date_time')}
                            className={cx('global!ace-u-grid-column--span-6', 'global!ace-u-full-width')}
                            errorClassName={cx('global!ace-u-margin--top-8')}
                            icon={calendarIcon}
                            value={temporaryRestrictionFormData?.restrictionEndDateTime || ''}
                            format="DD.MM.YYYY, HH:mm"
                            errors={hasDurationError
                                ? [translateModal('end_date_error_message.end_date_before_start')]
                                : []}
                        />
                    </div>
                    <Checkbox
                        name="restrictionRequest"
                        className={cx('global!ace-u-margin--bottom-24')}
                        isSelected={temporaryRestrictionFormData?.restrictionRequest || false}
                        value={true}
                    >
                        {translateModal('checkbox_label.permission_report_restriction_requested')}
                    </Checkbox>
                    <div className={cx('global!ace-u-margin--bottom-16')}>
                        <span className={cx(['global!ace-u-typography--variant-body-bold'])}>
                            {translateModal('text.restriction')}
                        </span>
                    </div>
                    <SelectField
                        name="restrictionCategory"
                        value={temporaryRestrictionFormData?.restrictionCategory || ''}
                        label={translateModal('input_label.restriction_category')}
                        placeholder={translateModal('input_placeholder.select')}
                        className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-full-width')}
                    >
                        {Object.keys(apmTemporaryRestrictionCategoryTypes).map(restrictionCategory => {
                            return (
                                <Option
                                    name={`restrictionCategory${camelCase(restrictionCategory)}`}
                                    value={restrictionCategory}
                                    key={restrictionCategory}
                                >
                                    {translate(`global.temporary_restriction_category.${snakeCase(restrictionCategory)}`)}
                                </Option>
                            );
                        })}
                    </SelectField>
                    <TextAreaField
                        className={cx('global!ace-u-full-width', 'global!ace-u-margin--bottom-24')}
                        name="description"
                        label={translateModal('input_label.description')}
                        placeholder={translateModal('input_placeholder.description')}
                        value={temporaryRestrictionFormData?.description || ''}
                        maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                        isResizable={false}
                    />
                    <div className={cx('global!ace-u-flex', 'global!ace-u-flex--justify-flex-end')}>
                        <ButtonPrimary
                            type="submit"
                            name="submitTemporaryRestrictionFormData"
                            isDisabled={isButtonDisabled}
                        >
                            {translateModal('button_label.save')}
                        </ButtonPrimary>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

CreateTemporaryRestrictionModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    contractPartner: PropTypes.object,
    declineCreateTemporaryRestriction: PropTypes.func.isRequired,
    confirmCreateTemporaryRestriction: PropTypes.func.isRequired,
};

CreateTemporaryRestrictionModal.defaultProps = {
    hasBackdrop: true,
    contractPartner: {},
};

const mapStateToProps = (state, props) => {
    const getContractPartner = contractPartnersSelectors.createContractPartnerSelector();
    return {
        contractPartner: getContractPartner(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineCreateTemporaryRestriction: () => dispatch({
        type: contractPartnerActionTypes.DECLINE_CREATE_TEMPORARY_RESTRICTION,
    }),
    confirmCreateTemporaryRestriction: payload => dispatch({
        type: contractPartnerActionTypes.CONFIRM_CREATE_TEMPORARY_RESTRICTION,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemporaryRestrictionModal);
