import {take, fork, select, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {EmergencyContact} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';

const createCPContactDataFlow = function* createCPContactDataFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_CREATE_CP_CONTACT_DATA_FLOW);
        const {contractPartnerId, isAdditional = false} = payload;

        yield* openModal(
            modalIds.CONTRACT_PARTNER_CONTACT_DATA,
            isAdditional ? {isAdditional: `${!!isAdditional}`} : undefined,
        );

        const chosenModalOption = yield take([
            contractPartnerActionTypes.CONFIRM_CREATE_CP_CONTACT_DATA,
            contractPartnerActionTypes.DECLINE_CREATE_CP_CONTACT_DATA,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_CREATE_CP_CONTACT_DATA
        ) {
            const {contactData} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.CREATE_CP_CONTACT_DATA_REQUEST,
                partnerManagementService.createCPContactData,
                {
                    contactDataDTO: EmergencyContact.objectToDTO(contactData),
                },
            );

            const responseAction = yield take([
                contractPartnerActionTypes.CREATE_CP_CONTACT_DATA_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.CREATE_CP_CONTACT_DATA_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {contactDataDTO} = response;

                yield put({
                    type: contractPartnerActionTypes.STORE_CP_CONTACT_DATA,
                    payload: {contactDataDTO, contractPartnerId},
                });
            }
        }

        yield* closeModal(
            modalIds.CONTRACT_PARTNER_CONTACT_DATA,
            isAdditional ? {isAdditional: `${!!isAdditional}`} : undefined,
        );
    }
};

export default createCPContactDataFlow;
