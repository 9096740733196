import {take, put, fork, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {User} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as userProfileActionTypes from '../userProfileActionTypes';

/**
 * User profile create flow (uses modal)
 * creates a new user on the EUP
 */
const createUserProfileFlow = function* createUserProfileFlow() {
    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userProfileService');

    while (true) {
        yield take(userProfileActionTypes.INITIATE_CREATE_USER_PROFILE_FLOW);

        yield* openModal(modalIds.USER_DETAILS);

        const chosenModalOption = yield take([
            userProfileActionTypes.CONFIRM_CREATE_USER_PROFILE,
            userProfileActionTypes.DECLINE_CREATE_USER_PROFILE,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === userProfileActionTypes.CONFIRM_CREATE_USER_PROFILE) {
            const userProfileData = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                userProfileActionTypes.CREATE_USER_PROFILE_REQUEST,
                userProfileService.createUserProfile,
                {
                    userProfileDTO: User.objectToDTO(userProfileData),
                },
            );
            const responseAction = yield take([
                userProfileActionTypes.CREATE_USER_PROFILE_REQUEST_SUCCEEDED,
                userProfileActionTypes.CREATE_USER_PROFILE_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {userProfileDTO} = response;

                yield put({
                    type: userProfileActionTypes.STORE_USER_DETAILS,
                    payload: {userProfileDTO},
                });
            }
        }

        yield* closeModal(modalIds.USER_DETAILS);
    }
};

export default createUserProfileFlow;
