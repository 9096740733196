import {take, select, put, fork} from 'redux-saga/effects';
import {ACEPartner, apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as commissionerActionTypes from '../commissionerActionTypes';

const updateCommissioner = function* updateCommissioner() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(commissionerActionTypes.SUBMIT_COMMISSIONER_FORM);
        const {commissionerId, commissionerData} = payload;

        yield fork(
            fetchRequest,
            commissionerActionTypes.UPDATE_COMMISSIONER_REQUEST,
            partnerManagementService.updateACEPartner,
            {
                acePartnerData: {
                    ...ACEPartner.objectToPatchDTO(commissionerData),
                    partnerType: apmACEPartnerTypes.COMMISSIONER,
                },
                acePartnerId: commissionerId,
            },
        );

        const responseAction = yield take([
            commissionerActionTypes.UPDATE_COMMISSIONER_REQUEST_SUCCEEDED,
            commissionerActionTypes.UPDATE_COMMISSIONER_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {acePartnerDTO: commissionerDTO} = response;

            yield put({
                type: commissionerActionTypes.STORE_COMMISSIONERS,
                payload: {commissionerDTOs: [commissionerDTO]},
            });
        }
    }
};

export default updateCommissioner;
