/**
 * @typedef {string} tabId
 */

/**
 * contractPartner screen tabs
 *
 * @enum {tabId}
 */
export default {
    BASIC_DATA: 'basic-data',
    CONTACT: 'contact',
    COMMISSIONING: 'commissioning',
    SERVICES: 'services',
    OPERATION_AREA: 'operation-area',
    CASE_HISTORY: 'case-history',
    TEMPORARY_RESTRICTIONS: 'temporary-restrictions',
    QUALITY_MANAGEMENT: 'quality-management',
};
