import {fork, put, take, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';

const createQualityManagementNoteQualificationFlow = function* createQualityManagementNoteQualificationFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_CREATE_QM_NOTE_QUALIFICATION_FLOW);
        const {feedback} = payload;

        yield* openModal(modalIds.QM_NOTE_QUALIFICATION, {feedbackId: `${feedback.id}`});

        const chosenModalOption = yield take([
            contractPartnerActionTypes.JUSTIFY_QUALITY_MANAGEMENT_FEEDBACK,
            contractPartnerActionTypes.CONFIRM_CREATE_QM_NOTE_QUALIFICATION,
            contractPartnerActionTypes.DECLINE_CREATE_QM_NOTE_QUALIFICATION,
        ]);

        if (chosenModalOption.type === contractPartnerActionTypes.JUSTIFY_QUALITY_MANAGEMENT_FEEDBACK) {
            const {justification, comment} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.JUSTIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST,
                partnerManagementService.createACEPartnerQualityManagementJustification,
                {
                    feedbackId: feedback.id,
                    justification,
                },
            );

            const responseAction = yield take([
                contractPartnerActionTypes.JUSTIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.JUSTIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_FAILED,
            ]);

            if (!responseAction.error && comment) {
                // create a new comment
                yield fork(
                    fetchRequest,
                    contractPartnerActionTypes.CREATE_CP_QM_NOTE_QUALIFICATION_REQUEST,
                    partnerManagementService.createACEPartnerQualityManagementComment,
                    {
                        feedbackId: feedback.id,
                        comment,
                    },
                );
            }
        }

        if (chosenModalOption.type === contractPartnerActionTypes.CONFIRM_CREATE_QM_NOTE_QUALIFICATION) {
            const {comment} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.CREATE_CP_QM_NOTE_QUALIFICATION_REQUEST,
                partnerManagementService.createACEPartnerQualityManagementComment,
                {
                    feedbackId: feedback.id,
                    comment,
                },
            );

            const responseAction = yield take([
                contractPartnerActionTypes.CREATE_CP_QM_NOTE_QUALIFICATION_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.CREATE_CP_QM_NOTE_QUALIFICATION_REQUEST_FAILED,
            ]);

            if (responseAction.error) {
                // handle errors if needed
            }
        }

        yield* closeModal(modalIds.QM_NOTE_QUALIFICATION, {feedbackId: `${feedback.id}`});

        // reset qm qualification note forwarding status
        yield put({
            type: contractPartnerActionTypes.STORE_QM_QUALIFICATION_NOTE_FORWARDING_STATUS,
            payload: {
                isQMQualificationNoteForwarded: false,
            },
        });
    }
};

export default createQualityManagementNoteQualificationFlow;
