import keycloakAuth from './keycloakAuth';
import applicationReducer from './application/applicationReducer';
import userReducer from './user-profiles/userProfileReducer';
import serviceProviderReducer from './service-providers/serviceProviderReducer';
import commissionerReducer from './commissioners/commissionerReducer';
import inaRulesReducer from './ina-rules/inaRulesReducer';
import acAccessControl from './acAccessControl';
import contractPartnerReducer from './contract-partners/contractPartnerReducer';
import arcGISAuth from './arcGISAuth';

// application reducers
const reducers = {
    application: applicationReducer,
    user: userReducer,
    serviceProviders: serviceProviderReducer,
    commissioners: commissionerReducer,
    contractPartners: contractPartnerReducer,
    inaRules: inaRulesReducer,
    ...keycloakAuth.reducer,
    ...arcGISAuth.reducer,
    ...acAccessControl.reducer,
};

export default reducers;
