import {fork, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {INARule} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as inaRulesActionTypes from '../inaRulesActionTypes';
import modalIds from '../../modalIds';

const initiateINARuleEditFlow = function* initiateINARuleEditFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take([inaRulesActionTypes.INITIATE_INA_RULE_EDIT_FLOW]);
        const {inaRuleId} = payload;

        yield* openModal(modalIds.INA_RULES_EDIT_MODAL, {inaRuleId: inaRuleId.toString()});

        const chosenAction = yield take([
            inaRulesActionTypes.CONFIRM_INA_RULE_EDIT,
            inaRulesActionTypes.DECLINE_INA_RULE_EDIT,
        ]);

        if (chosenAction.type === inaRulesActionTypes.CONFIRM_INA_RULE_EDIT) {
            const {payload: chosenActionPayload} = chosenAction;
            const {inaRuleId, inaRuleData} = chosenActionPayload;

            yield fork(
                fetchRequest,
                inaRulesActionTypes.UPDATE_INA_RULE_REQUEST,
                leaAssignmentFlowService.updateINARule,
                {
                    ruleId: inaRuleId,
                    inaRulePatchDTO: INARule.objectToPatchDTO(inaRuleData),
                },
            );

            yield take([
                inaRulesActionTypes.UPDATE_INA_RULE_REQUEST_SUCCEEDED,
                inaRulesActionTypes.UPDATE_INA_RULE_REQUEST_FAILED,
            ]);
        }

        yield* closeModal(modalIds.INA_RULES_EDIT_MODAL, {inaRuleId: inaRuleId.toString()});
    }
};

export default initiateINARuleEditFlow;
