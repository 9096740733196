/**
 * Route paths
 */
export default {
    ROOT: '/',
    KEYCLOAK_AUTHORIZE: '/oidc/authorize',
    KEYCLOAK_AUTHENTICATE: '/oidc/authenticate',
    ARCGIS_AUTHORIZE: '/oauth2/authorize',
    ARCGIS_AUTHENTICATE: '/oauth2/authenticate',
    DASHBOARD_SECTION: '/dashboard',
    DASHBOARD: '/dashboard/',
    USER_MANAGEMENT: '/dashboard/user-management/:userId?',
    SERVICE_PROVIDER_SEARCH: '/dashboard/service-provider-search',
    SERVICE_PROVIDER: '/service-providers/:serviceProviderId',
    COMMISSIONER_SEARCH: '/dashboard/commissioner-search',
    COMMISSIONER: '/commissioners/:commissionerId',
    CONTRACT_PARTNER_SEARCH: '/dashboard/contract-partner-search',
    CONTRACT_PARTNER: '/contract-partners/:contractPartnerId',
    TEXT_MANAGEMENT: '/dashboard/text-management',
    STANDBY_TIMES: '/emergency-lawyers/standby-times',
    INA_RULES: '/dashboard/ina-rules',
    SYSTEM_ACCESS_DENIED: '/access/403',
    ROUTE_ACCESS_DENIED: '/403',
};
