/**
 * Command action types
 */
export const SEARCH_SERVICE_PROVIDERS = '[service-providers] SEARCH_SERVICE_PROVIDERS';
export const SUBMIT_SERVICE_PROVIDER_FORM = '[service-providers] SUBMIT_SERVICE_PROVIDER_FORM';
export const INITIATE_CHANGE_SP_ACTIVE_STATE_FLOW = '[service-providers] INITIATE_CHANGE_SP_ACTIVE_STATE_FLOW';
export const CONFIRM_SERVICE_PROVIDER_DEACTIVATE = '[service-providers] CONFIRM_SERVICE_PROVIDER_DEACTIVATE';
export const DECLINE_SERVICE_PROVIDER_DEACTIVATE = '[service-providers] DECLINE_SERVICE_PROVIDER_DEACTIVATE';
export const INITIATE_CREATE_STANDBY_TIMES_ENTRY_FLOW = '[service-providers] INITIATE_CREATE_STANDBY_TIMES_ENTRY_FLOW';
export const INITIATE_UPDATE_STANDBY_TIMES_FLOW = '[service-providers] INITIATE_UPDATE_STANDBY_TIMES_FLOW';
export const CONFIRM_CREATE_STANDBY_TIMES_ENTRY = '[service-providers] CONFIRM_CREATE_STANDBY_TIMES_ENTRY';
export const DECLINE_CREATE_STANDBY_TIMES_ENTRY = '[service-providers] DECLINE_CREATE_STANDBY_TIMES_ENTRY';
export const DECLINE_UPDATE_STANDBY_TIMES_ENTRY = '[service-providers] DECLINE_UPDATE_STANDBY_TIMES_ENTRY';
export const CONFIRM_UPDATE_STANDBY_TIMES_ENTRY = '[service-providers] CONFIRM_UPDATE_STANDBY_TIMES_ENTRY';
export const INITIATE_CREATE_SP_RENTAL_CAR_PRICE_DATA_FLOW = '[service-providers] INITIATE_CREATE_SP_RENTAL_CAR_PRICE_DATA_FLOW';
export const CONFIRM_CREATE_SP_RENTAL_CAR_PRICING = '[service-providers] CONFIRM_CREATE_SP_RENTAL_CAR_PRICING';
export const DECLINE_CREATE_SP_RENTAL_CAR_PRICING = '[service-providers] DECLINE_CREATE_SP_RENTAL_CAR_PRICING';
export const INITIATE_DELETE_SP_RENTAL_CAR_PRICE_DATA_FLOW = '[service-providers] INITIATE_DELETE_SP_RENTAL_CAR_PRICE_DATA_FLOW';
export const CONFIRM_DELETE_SP_RENTAL_CAR_PRICING = '[service-providers] CONFIRM_DELETE_SP_RENTAL_CAR_PRICING';
export const DECLINE_DELETE_SP_RENTAL_CAR_PRICING = '[service-providers] DECLINE_DELETE_SP_RENTAL_CAR_PRICING';
export const INITIATE_UPDATE_SP_RENTAL_CAR_PRICE_DATA_FLOW = '[service-providers] INITIATE_UPDATE_SP_RENTAL_CAR_PRICE_DATA_FLOW';
export const INITIATE_UPDATE_RC_ADDITIONAL_SERVICES_PRICE_DATA_FLOW = '[service-providers] INITIATE_UPDATE_RC_ADDITIONAL_SERVICES_PRICE_DATA_FLOW';
export const INITIATE_CREATE_RC_ADDITIONAL_SERVICES_PRICE_DATA_FLOW = '[service-providers] INITIATE_CREATE_RC_ADDITIONAL_SERVICES_PRICE_DATA_FLOW';
export const CONFIRM_UPDATE_SP_RENTAL_CAR_PRICING = '[service-providers] CONFIRM_UPDATE_SP_RENTAL_CAR_PRICING';
export const DECLINE_UPDATE_SP_RENTAL_CAR_PRICING = '[service-providers] DECLINE_UPDATE_SP_RENTAL_CAR_PRICING';

/**
 * Event action types
 */
export const SEARCH_SERVICE_PROVIDERS_REQUEST = '[service-providers] SEARCH_SERVICE_PROVIDERS_REQUEST';
export const SEARCH_SERVICE_PROVIDERS_REQUEST_SENT = '[service-providers] SEARCH_SERVICE_PROVIDERS_REQUEST_SENT';
export const SEARCH_SERVICE_PROVIDERS_REQUEST_SUCCEEDED = '[service-providers] SEARCH_SERVICE_PROVIDERS_REQUEST_SUCCEEDED';
export const SEARCH_SERVICE_PROVIDERS_REQUEST_FAILED = '[service-providers] SEARCH_SERVICE_PROVIDERS_REQUEST_FAILED';

export const FETCH_SERVICE_PROVIDER_REQUEST = '[service-providers] FETCH_SERVICE_PROVIDER_REQUEST';
export const FETCH_SERVICE_PROVIDER_REQUEST_SENT = '[service-providers] FETCH_SERVICE_PROVIDER_REQUEST_SENT';
export const FETCH_SERVICE_PROVIDER_REQUEST_SUCCEEDED = '[service-providers] FETCH_SERVICE_PROVIDER_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_PROVIDER_REQUEST_FAILED = '[service-providers] FETCH_SERVICE_PROVIDER_REQUEST_FAILED';

export const UPDATE_SERVICE_PROVIDER_REQUEST = '[service-providers] UPDATE_SERVICE_PROVIDER_REQUEST';
export const UPDATE_SERVICE_PROVIDER_REQUEST_SENT = '[service-providers] UPDATE_SERVICE_PROVIDER_REQUEST_SENT';
export const UPDATE_SERVICE_PROVIDER_REQUEST_SUCCEEDED = '[service-providers] UPDATE_SERVICE_PROVIDER_REQUEST_SUCCEEDED';
export const UPDATE_SERVICE_PROVIDER_REQUEST_FAILED = '[service-providers] UPDATE_SERVICE_PROVIDER_REQUEST_FAILED';

export const FETCH_EMERGENCY_LAWYERS_REQUEST = '[service-providers] FETCH_EMERGENCY_LAWYERS_REQUEST';
export const FETCH_EMERGENCY_LAWYERS_REQUEST_SENT = '[service-providers] FETCH_EMERGENCY_LAWYERS_REQUEST_SENT';
export const FETCH_EMERGENCY_LAWYERS_REQUEST_SUCCEEDED = '[service-providers] FETCH_EMERGENCY_LAWYERS_REQUEST_SUCCEEDED';
export const FETCH_EMERGENCY_LAWYERS_REQUEST_FAILED = '[service-providers] FETCH_EMERGENCY_LAWYERS_REQUEST_FAILED';

export const UPDATE_STANDBY_TIMES_REQUEST = '[service-providers] UPDATE_STANDBY_TIMES_REQUEST';
export const UPDATE_STANDBY_TIMES_REQUEST_SENT = '[service-providers] UPDATE_STANDBY_TIMES_REQUEST_SENT';
export const UPDATE_STANDBY_TIMES_REQUEST_SUCCEEDED = '[service-providers] UPDATE_STANDBY_TIMES_REQUEST_SUCCEEDED';
export const UPDATE_STANDBY_TIMES_REQUEST_FAILED = '[service-providers] UPDATE_STANDBY_TIMES_REQUEST_FAILED';

export const FETCH_SERVICE_PROVIDER_RENTAL_CAR_PRICING_REQUEST = '[service-providers] FETCH_SERVICE_PROVIDER_RENTAL_CAR_PRICING_REQUEST';
export const FETCH_SERVICE_PROVIDER_RENTAL_CAR_PRICING_REQUEST_SENT = '[service-providers] FETCH_SERVICE_PROVIDER_RENTAL_CAR_PRICING_REQUEST_SENT';
export const FETCH_SERVICE_PROVIDER_RENTAL_CAR_PRICING_REQUEST_SUCCEEDED = '[service-providers] FETCH_SERVICE_PROVIDER_RENTAL_CAR_PRICING_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_PROVIDER_RENTAL_CAR_PRICING_REQUEST_FAILED = '[service-providers] FETCH_SERVICE_PROVIDER_RENTAL_CAR_PRICING_REQUEST_FAILED';

export const CREATE_SP_RENTAL_CAR_PRICING_REQUEST = '[service-providers] CREATE_SP_RENTAL_CAR_PRICING_REQUEST';
export const CREATE_SP_RENTAL_CAR_PRICING_REQUEST_SENT = '[service-providers] CREATE_SP_RENTAL_CAR_PRICING_REQUEST_SENT';
export const CREATE_SP_RENTAL_CAR_PRICING_REQUEST_SUCCEEDED = '[service-providers] CREATE_SP_RENTAL_CAR_PRICING_REQUEST_SUCCEEDED';
export const CREATE_SP_RENTAL_CAR_PRICING_REQUEST_FAILED = '[service-providers] CREATE_SP_RENTAL_CAR_PRICING_REQUEST_FAILED';

export const UPDATE_SP_RENTAL_CAR_PRICING_REQUEST = '[service-providers] UPDATE_SP_RENTAL_CAR_PRICING_REQUEST';
export const UPDATE_SP_RENTAL_CAR_PRICING_REQUEST_SENT = '[service-providers] UPDATE_SP_RENTAL_CAR_PRICING_REQUEST_SENT';
export const UPDATE_SP_RENTAL_CAR_PRICING_REQUEST_SUCCEEDED = '[service-providers] UPDATE_SP_RENTAL_CAR_PRICING_REQUEST_SUCCEEDED';
export const UPDATE_SP_RENTAL_CAR_PRICING_REQUEST_FAILED = '[service-providers] UPDATE_SP_RENTAL_CAR_PRICING_REQUEST_FAILED';

export const DELETE_SP_RENTAL_CAR_PRICING_REQUEST = '[service-providers] DELETE_SP_RENTAL_CAR_PRICING_REQUEST';
export const DELETE_SP_RENTAL_CAR_PRICING_REQUEST_SENT = '[service-providers] DELETE_SP_RENTAL_CAR_PRICING_REQUEST_SENT';
export const DELETE_SP_RENTAL_CAR_PRICING_REQUEST_SUCCEEDED = '[service-providers] DELETE_SP_RENTAL_CAR_PRICING_REQUEST_SUCCEEDED';
export const DELETE_SP_RENTAL_CAR_PRICING_REQUEST_FAILED = '[service-providers] DELETE_SP_RENTAL_CAR_PRICING_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_SERVICE_PROVIDER_SEARCH_RESULTS = '[service-providers] STORE_SERVICE_PROVIDER_SEARCH_RESULTS';
export const STORE_SERVICE_PROVIDERS = '[service-providers] STORE_SERVICE_PROVIDERS';
export const STORE_EMERGENCY_LAWYERS = '[service-providers] STORE_EMERGENCY_LAWYERS';
export const STORE_SERVICE_PROVIDER_RENTAL_CAR_PRICING = '[service-providers] STORE_SERVICE_PROVIDER_RENTAL_CAR_PRICING';
export const REMOVE_SP_RENTAL_CAR_PRICING = '[service-providers] REMOVE_SP_RENTAL_CAR_PRICING';
export const SET_RENTAL_CAR_ADDITIONAL_SERVICES_MAP = '[service-providers] SET_RENTAL_CAR_ADDITIONAL_SERVICES_MAP';
