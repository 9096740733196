import React, {Fragment, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase, camelCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {resolveRoute} from '@computerrock/formation-router';
import {apmContractPartnerContractStatusTypes, apmTemporaryRestrictionCategoryTypes} from '@ace-de/eua-entity-types';
import {Badge, NoResultsBlock, Paginator, Panel, useStyles, Option, ButtonIcon, MultiSelectOption} from '@ace-de/ui-components';
import {InputField, SelectField, MultiSelectField} from '@ace-de/ui-components/form';
import {Icon, waitingIcon, InteractiveIcon, findCaseIcon, searchIcon, resetIcon, questionmarkIcon, noServiceIcon, otherIcon} from '@ace-de/ui-components/icons';
import {Table, TableBody, TableCaption, TableCell, TableHead, TableRow} from '@ace-de/ui-components/data-elements';
import routePaths from '../routePaths';
import * as contractPartnerSelectors from './contractPartnerSelectors';
import config from '../config';

const multiselectFields = ['contractStatus'];

const initialContractPartnerFilteringParams = {
    searchTerm: '',
    contractStatus: '',
};

const sortingOptions = {
    ASC: 'ASC',
    DESC: 'DESC',
};

const categoryIconMap = {
    [apmTemporaryRestrictionCategoryTypes.RESTRICTED_SERVICES]: questionmarkIcon,
    [apmTemporaryRestrictionCategoryTypes.TEMPORARILY_CLOSED]: noServiceIcon,
    [apmTemporaryRestrictionCategoryTypes.WAITING_TIME]: waitingIcon,
    [apmTemporaryRestrictionCategoryTypes.OTHER]: otherIcon,
};

const ContractPartnerSearchScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('contract_partner_search_screen');
    const {history, contractPartnerSearchResults, contractPartnerSearchResultsCount} = props;
    const [queryParams, setQueryParams] = useState(new URLSearchParams());
    const paginatorCount = Math.ceil(contractPartnerSearchResultsCount / config.DEFAULT_PAGE_SIZE);
    const [formData, setFormData] = useState(initialContractPartnerFilteringParams);
    const [sortingDirection, setSortingDirection] = useState(queryParams.get('sort')
        ? {sort: queryParams.get('sort')}
        : {sort: `name,${sortingOptions.ASC}`});
    const topElementRef = useRef(null);

    const openContractPartnerScreen = contractPartner => {
        history.push(resolveRoute(routePaths.CONTRACT_PARTNER, {
            contractPartnerId: contractPartner.id,
        }));
    };

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
                // handle new query parameters and update form data
                const multiselectFieldsValues = {};
                multiselectFields.forEach(fieldName => {
                    multiselectFieldsValues[fieldName] = newQueryParams.getAll(fieldName).length
                        ? newQueryParams.getAll(fieldName) : '';
                });

                setFormData({
                    searchTerm: newQueryParams.get('searchTerm') || '',
                    ...multiselectFieldsValues,
                });
            }
        }
    }, [history.location.search, queryParams]);

    const handlePaginationPage = page => {
        topElementRef.current?.scrollIntoView({behavior: 'smooth'});
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);
        apiQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.CONTRACT_PARTNER_SEARCH, {}, {search: queryParamsString}));
    };

    const formatQueryParams = formData => {
        if (!formData) return;
        const apiQueryParams = new URLSearchParams();

        Object.keys(formData).forEach(formField => {
            // handle multiselect fields
            if (multiselectFields.includes(formField) && Array.isArray(formData[formField])) {
                formData[formField].forEach(fieldValue => {
                    if (fieldValue) {
                        apiQueryParams.append(`${formField}`, fieldValue);
                    }
                });
                return;
            }

            if (formData[formField] !== undefined && formData[formField] !== '') {
                apiQueryParams.append(`${formField}`, formData[formField]);
            }
        });
        return apiQueryParams;
    };

    const onSubmit = () => {
        if (!formData) return;

        const apiQueryParams = formatQueryParams({
            ...formData,
            ...sortingDirection,
        });

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';

        history.push(resolveRoute(routePaths.CONTRACT_PARTNER_SEARCH, {}, {search: queryParamsString}));
    };

    const onChange = (key, value) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleResetFilter = () => {
        setFormData(initialContractPartnerFilteringParams);

        const queryParams = new URLSearchParams(history.location.search);
        const apiQueryParams = formatQueryParams(sortingDirection);
        const queryParamsString = queryParams.get('sort') ? apiQueryParams.toString() : '';

        history.push(resolveRoute(routePaths.CONTRACT_PARTNER_SEARCH, {}, {search: queryParamsString}));
    };

    return (
        <Fragment>
            <Panel
                title={translateScreen('panel_title.filter_and_search')}
                contentClassName={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}
            >
                <Fragment>
                    <div className={cx(['global!ace-u-grid', 'global!ace-u-margin--bottom-32'])}>
                        <div
                            className={cx('global!ace-u-grid-column--span-7')}
                            onKeyDown={event => {
                                if (event.key === 'Enter') onSubmit();
                            }}
                        >
                            <InputField
                                name="searchTerm"
                                value={formData?.searchTerm}
                                label={translateScreen('input_field_label.free_search')}
                                className={cx(['global!ace-u-width--full', 'global!ace-u-margin--top-8'])}
                                onChange={value => onChange('searchTerm', value)}
                            />
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <MultiSelectField
                                name="contractStatus"
                                value={formData?.contractStatus}
                                label={translateScreen('select_field_label.status')}
                                className={cx(['global!ace-u-width--full', 'global!ace-u-margin--top-8'])}
                                onChange={value => onChange('contractStatus', value)}
                            >
                                {Object.keys(apmContractPartnerContractStatusTypes).map(statusType => (
                                    <MultiSelectOption
                                        key={statusType}
                                        value={statusType}
                                        name={`contractStatusOption${camelCase(statusType)}`}
                                    >
                                        {translate(`global.contract_status.${snakeCase(statusType)}`)}
                                    </MultiSelectOption>
                                ))}
                            </MultiSelectField>
                        </div>
                        <div className={cx('global!ace-u-grid-column--span-2')}>
                            <SelectField
                                name="sort"
                                label={translateScreen('select_field_label.sort')}
                                className={cx(['global!ace-u-width--full', 'global!ace-u-margin--top-8'])}
                                value={sortingDirection.sort}
                                onChange={value => setSortingDirection({sort: value})}
                            >
                                {Object.keys(sortingOptions)
                                    .map(sortingOption => (
                                        <Option
                                            key={sortingOption}
                                            value={`name,${sortingOption}`}
                                            name={`sortingOption${camelCase(sortingOption)}`}
                                        >
                                            {translateScreen(`select_field_option.${sortingOption.toLowerCase()}`)}
                                        </Option>
                                    ))}
                            </SelectField>
                        </div>
                        <div
                            className={cx([
                                'global!ace-u-grid-column--span-1',
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-flex-end',
                                'global!ace-u-padding--top-24',
                            ])}
                        >
                            <ButtonIcon
                                name="searchButton"
                                icon={searchIcon}
                                onClick={onSubmit}
                                className={cx('global!ace-u-margin--top-8')}
                            />
                        </div>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-space-between',
                        ])}
                    >
                        <div>
                            <span className={cx('global!ace-u-typography--variant-body')}>
                                {translateScreen('label.result_count')}
                            </span>&nbsp;
                            <span className={cx('global!ace-u-typography--variant-body-medium')}>
                                {contractPartnerSearchResultsCount}
                            </span>
                        </div>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                            ])}
                        >
                            <div className={cx('global!ace-u-margin--right-64')}>
                                <InteractiveIcon
                                    icon={resetIcon}
                                    className={cx([
                                        'ace-c-interactive-icon--reverse',
                                        'ace-c-interactive-icon--highlight',
                                    ])}
                                    onClick={handleResetFilter}
                                >
                                    {translateScreen('interactive_icon_label.reset_filter')}
                                </InteractiveIcon>
                            </div>
                        </div>
                    </div>
                </Fragment>
            </Panel>
            <Panel className={cx('ace-c-panel--full-bleed')}>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <h3
                        className={cx([
                            'global!ace-u-margin--32',
                            'global!ace-u-typography--variant-h3',
                        ])}
                    >
                        {translateScreen('panel_title.contract_partners')}
                    </h3>
                </div>
                <Table qaIdent="contract-partner-search-results">
                    {contractPartnerSearchResults.length === 0 && (
                        <TableCaption>
                            <NoResultsBlock
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl')}
                                        icon={findCaseIcon}
                                    />
                                )}
                                message={translateScreen('no_results.message')}
                                description={translateScreen('no_results.description')}
                            />
                        </TableCaption>
                    )}
                    <TableHead>
                        <TableRow>
                            <TableCell qaIdentPart="cp-name">
                                {translateScreen('table_header.name')}
                            </TableCell>
                            <TableCell qaIdentPart="cp-contact-details-phone">
                                {translateScreen('table_header.phone_number')}
                            </TableCell>
                            <TableCell qaIdentPart="cp-contract-status">
                                {translateScreen('table_header.status')}
                            </TableCell>
                            <TableCell qaIdentPart="cp-address-street">
                                {translateScreen('table_header.street')}
                            </TableCell>
                            <TableCell qaIdentPart="cp-address-post-code">
                                {translateScreen('table_header.post_code')}
                            </TableCell>
                            <TableCell qaIdentPart="cp-address-city">
                                {translateScreen('table_header.city')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="cp-restrictions">
                                {translateScreen('table_header.restrictions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contractPartnerSearchResults.length > 0
                        && contractPartnerSearchResults.map(contractPartner => {
                            return (
                                <TableRow
                                    key={contractPartner.id}
                                    onClick={() => openContractPartnerScreen(contractPartner)}
                                    qaIdentPart={contractPartner.id}
                                >
                                    <TableCell
                                        qaIdentPart="cp-name"
                                        qaIdentPartPostfix={contractPartner.id}
                                    >
                                        {contractPartner.name || ''}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="cp-contact-details-phone"
                                        qaIdentPartPostfix={contractPartner.id}
                                    >
                                        {contractPartner.businessContactDetails?.phoneNo || ''}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="cp-contract-status"
                                        qaIdentPartPostfix={contractPartner.id}
                                    >
                                        {contractPartner.contractStatus
                                            ? translate(`global.contract_status.${snakeCase(contractPartner.contractStatus)}`)
                                            : ''
                                        }
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="cp-address-street"
                                        qaIdentPartPostfix={contractPartner.id}
                                    >
                                        {contractPartner.address?.street || ''}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="cp-address-post-code"
                                        qaIdentPartPostfix={contractPartner.id}
                                    >
                                        {contractPartner.address?.postCode || ''}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="cp-address-city"
                                        qaIdentPartPostfix={contractPartner.id}
                                    >
                                        {contractPartner.address?.city || ''}
                                    </TableCell>
                                    <TableCell
                                        colSpan={2}
                                        qaIdentPart="cp-restrictions"
                                        qaIdentPartPostfix={contractPartner.id}
                                    >
                                        {contractPartner.lastRestrictionType ? (
                                            <div
                                                className={cx([
                                                    'global!ace-u-inline-flex',
                                                    'global!ace-u-flex--align-center',
                                                    'global!ace-u-flex--justify-flex-start',
                                                ])}
                                            >
                                                <Icon
                                                    icon={categoryIconMap[contractPartner?.lastRestrictionType]}
                                                    className={cx(['ace-c-icon--m', 'global!ace-u-margin--right-8'])}
                                                />
                                                <div>
                                                    {translate(
                                                        `global.temporary_restriction_category.${snakeCase(
                                                            contractPartner?.lastRestrictionType,
                                                        )}`,
                                                    )}
                                                </div>
                                                {typeof contractPartner.activeRestrictions === 'number'
                                                && contractPartner.activeRestrictions > 1 && (
                                                    <Badge
                                                        className={cx([
                                                            'ace-c-badge--status-notification',
                                                            'global!ace-u-margin--left-8',
                                                        ])}
                                                    >
                                                        {`+${contractPartner.activeRestrictions - 1}`}
                                                    </Badge>
                                                )}
                                            </div>
                                        ) : '-'}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Panel>
            {contractPartnerSearchResults.length > 0 && (
                <Paginator
                    page={queryParams?.get('page') ? parseInt(queryParams.get('page'), 10) : 0}
                    count={paginatorCount}
                    onClick={handlePaginationPage}
                />
            )}
        </Fragment>
    );
};

ContractPartnerSearchScreen.propTypes = {
    history: PropTypes.object.isRequired,
    contractPartnerSearchResults: PropTypes.array,
    contractPartnerSearchResultsCount: PropTypes.number,
};

ContractPartnerSearchScreen.defaultProps = {
    contractPartnerSearchResults: [],
    contractPartnerSearchResultsCount: 0,
};

const mapStateToProps = state => {
    return {
        contractPartnerSearchResults: contractPartnerSelectors.getContractPartnerSearchResults(state),
        contractPartnerSearchResultsCount: contractPartnerSelectors.getContractPartnerSearchResultsCount(state),
    };
};

export default connect(mapStateToProps, null)(ContractPartnerSearchScreen);
