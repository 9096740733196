import {fork, put, select, take} from 'redux-saga/effects';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';

const submitQMNoteQualificationEmailForm = function* submitQMNoteQualificationEmailForm() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.SUBMIT_QM_NOTE_QUALIFICATION_EMAIL_FORM);

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.FORWARD_QUALITY_MANAGEMENT_NOTE_REQUEST,
            partnerManagementService.forwardACEPartnerQualityManagementNote,
            {
                feedbackId: payload.feedbackId,
                emailData: payload.data,
            },
        );

        const responseAction = yield take([
            contractPartnerActionTypes.FORWARD_QUALITY_MANAGEMENT_NOTE_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.FORWARD_QUALITY_MANAGEMENT_NOTE_REQUEST_FAILED,
        ]);

        // update qm qualification note forwarding status
        yield put({
            type: contractPartnerActionTypes.STORE_QM_QUALIFICATION_NOTE_FORWARDING_STATUS,
            payload: {
                isQMQualificationNoteForwarded: !responseAction.error,
            },
        });
    }
};

export default submitQMNoteQualificationEmailForm;
