import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {resolveRoute} from '@computerrock/formation-router';
import {useStyles, Panel, ButtonPrimary, Paginator, NoResultsBlock, Avatar} from '@ace-de/ui-components';
import {InputField, ButtonIcon} from '@ace-de/ui-components/form';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, InteractiveIcon, resetIcon, findCaseIcon, editIcon, searchIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import * as userProfileSelectors from './userProfileSelectors';
import * as userProfileActionTypes from './userProfileActionTypes';
import {acFeatures, acFeatureActions} from '../application/acFeatures';
import acAccessControl from '../acAccessControl';
import config from '../config';
import routePaths from '../routePaths';

const UserSearchScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('user_management_search_screen');
    const {initiateEditUserDetailsFlow, initiateCreateUserDetailsFlow} = props;
    const {history, userSearchResultsCount, userSearchResults} = props;
    const [userSearchQuery, setUserSearchQuery] = useState('');
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const paginatorCount = Math.ceil(userSearchResultsCount / config.DEFAULT_PAGE_SIZE);

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
            }
        }
    }, [history.location.search, queryParams]);

    const handleResetFilter = () => {
        setUserSearchQuery('');
        history.push(resolveRoute(routePaths.USER_MANAGEMENT, {}, {search: ''}));
    };

    const handleOnSubmit = () => {
        if (!userSearchQuery) return;

        const apiQueryParams = new URLSearchParams();
        if (userSearchQuery !== '') {
            apiQueryParams.append('searchTerm', userSearchQuery);
        }

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.USER_MANAGEMENT, {}, {search: queryParamsString}));
    };

    const handlePaginationPage = page => {
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);
        apiQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.USER_MANAGEMENT, {}, {search: queryParamsString}));
    };

    const openEditUserDetailsModal = user => {
        const pathParams = {
            userId: user.id,
        };
        const queryParamsString = queryParams.toString();
        history.replace(resolveRoute(routePaths.USER_MANAGEMENT, pathParams, {search: queryParamsString}));
        initiateEditUserDetailsFlow({
            history,
            queryParamsString,
            pathParams,
        });
    };

    return (
        <Fragment>
            <Panel title={translateScreen('user_search_panel.title')}>
                <div className={cx('global!ace-u-grid')}>
                    <div className={cx('global!ace-u-grid-column--span-6')}>
                        <InputField
                            label={translateScreen('input_field_label.free_search')}
                            name="searchTerm"
                            value={userSearchQuery}
                            onChange={value => setUserSearchQuery(value)}
                            className={cx('global!ace-u-full-width')}
                            onKeyDown={handleOnSubmit}
                        />
                    </div>
                    <div className={cx(['global!ace-u-grid-column--span-1', 'global!ace-u-margin--top-24'])}>
                        <ButtonIcon
                            name="searchButton"
                            icon={searchIcon}
                            className={cx('global!ace-u-margin--top-4')}
                            onClick={handleOnSubmit}
                        />
                    </div>
                </div>
                <InteractiveIcon
                    icon={resetIcon}
                    className={cx([
                        'ace-c-interactive-icon--reverse',
                        'ace-c-interactive-icon--highlight',
                        'global!ace-u-margin--top-24',
                    ])}
                    onClick={handleResetFilter}
                >
                    {translateScreen('interactive_icon_label.reset_filter')}
                </InteractiveIcon>
            </Panel>
            <Panel title={translateScreen('user_panel.title')}>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                    ])}
                >
                    {acAccessControl.grantFeatureAccess(acFeatures.USER_ACCOUNT, acFeatureActions.CREATE) && (
                        <ButtonPrimary onClick={initiateCreateUserDetailsFlow}>
                            {translateScreen('user_panel_button.add_users')}
                        </ButtonPrimary>
                    )}
                </div>
                <div className={cx('global!ace-u-margin--top-32')}>
                    <Table qaIdent="user-search-results">
                        {userSearchResults.length === 0 && (
                            <TableCaption>
                                <NoResultsBlock
                                    icon={(
                                        <Icon
                                            className={cx('ace-c-icon--xxl')}
                                            icon={findCaseIcon}
                                        />
                                    )}
                                    message={translateScreen('no_results.message')}
                                    description={translateScreen('no_results.description')}
                                />
                            </TableCaption>
                        )}
                        <TableHead>
                            <TableRow>
                                <TableCell qaIdentPart="initials" colSpan={2} />
                                <TableCell qaIdentPart="first-name" colSpan={5}>
                                    {translateScreen('user_table_cell.first_name')}
                                </TableCell>
                                <TableCell qaIdentPart="last-name" colSpan={5}>
                                    {translateScreen('user_table_cell.last_name')}
                                </TableCell>
                                <TableCell qaIdentPart="e-mail-address" colSpan={5}>
                                    {translateScreen('user_table_cell.e_mail_address')}
                                </TableCell>
                                <TableCell qaIdentPart="edit" colSpan={1} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userSearchResults.length > 0 && userSearchResults.map(user => {
                                const userLastName = user.lastName ? user.lastName : '';

                                return (
                                    <TableRow
                                        key={user.id}
                                        qaIdentPart={user.id}
                                    >
                                        <TableCell
                                            qaIdentPart="initials"
                                            qaIdentPartPostfix={user.id}
                                            colSpan={2}
                                        >
                                            <Avatar alt={user.initials} />
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="first-name"
                                            qaIdentPartPostfix={user.id}
                                            colSpan={5}
                                        >
                                            {user.firstName}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="last-name"
                                            qaIdentPartPostfix={user.id}
                                            colSpan={5}
                                        >
                                            {!user.isEnabled
                                                ? (
                                                    <p>
                                                        {userLastName}
                                                        <span
                                                            className={cx([
                                                                'global!ace-u-typography--color-highlighted',
                                                                'global!ace-u-padding--left-4',
                                                            ])}
                                                        >
                                                            {translateScreen('user_table_cell.deactivated')}
                                                        </span>
                                                    </p>
                                                ) : userLastName}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="e-mail-address"
                                            qaIdentPartPostfix={user.id}
                                            colSpan={5}
                                        >
                                            {user.email}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="edit"
                                            qaIdentPartPostfix={user.id}
                                            colSpan={1}
                                        >
                                            <InteractiveIcon
                                                icon={editIcon}
                                                className={cx('ace-c-interactive-icon--primary')}
                                                onClick={() => openEditUserDetailsModal(user)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </Panel>
            {userSearchResults.length > 0 && (
                <Paginator
                    page={+queryParams.get('page')}
                    count={paginatorCount}
                    onClick={handlePaginationPage}
                />
            )}
        </Fragment>
    );
};

UserSearchScreen.propTypes = {
    history: PropTypes.object.isRequired,
    userSearchResults: PropTypes.array,
    userSearchResultsCount: PropTypes.number,
    initiateEditUserDetailsFlow: PropTypes.func.isRequired,
    initiateCreateUserDetailsFlow: PropTypes.func.isRequired,
};

UserSearchScreen.defaultProps = {
    userSearchResults: [],
    userSearchResultsCount: 0,
};

const mapStateToProps = (state, props) => {
    return {
        userSearchResults: userProfileSelectors.getUserSearchResults(state, props),
        userSearchResultsCount: state.user.userSearchResultsCount,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateEditUserDetailsFlow: payload => dispatch({
        type: userProfileActionTypes.INITIATE_EDIT_USER_PROFILE_FLOW,
        payload,
    }),
    initiateCreateUserDetailsFlow: payload => dispatch({
        type: userProfileActionTypes.INITIATE_CREATE_USER_PROFILE_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchScreen);
