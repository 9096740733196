import {ServiceManager} from '@computerrock/formation-core';
import {I18nService} from '@computerrock/formation-i18n';
import {OpenIDConnectClient} from '@computerrock/oidc-auth-client';
import {OAuth2Client} from '@computerrock/oauth2-auth-client';
import {DatadogLoggerClient} from '@computerrock/datadog-logger-client';
import {MSClientECSUserProfileEUP} from '@ace-de/ms-client-ecs-user-profile-eup';
import {MSClientECSFlowEF} from '@ace-de/ms-client-ecs-flow-ef';
import {MSClientACEPartnerManagementAPM} from '@ace-de/ms-client-ace-partner-management-apm';
import {ArcGISRESTClient} from '@ace-de/eua-arcgis-rest-client';
import {ArcGISMapService} from '@ace-de/eua-arcgis-map';
import {EUAFileAssetsService} from '@ace-de/eua-file-assets';
import {MSClientLEAAssignmentFlowALF} from '@ace-de/ms-client-lea-assignment-flow-alf';
import config from './config';
import defaultLocaleTranslations from './locale';

// register application services
const serviceManager = new ServiceManager();

// initialize Datadog logger
if (config.DATADOG_ENABLE) {
    const ffwLoggerService = serviceManager.loadService('ffwLoggerService');
    ffwLoggerService.setLoggerClient(new DatadogLoggerClient({
        applicationId: config.DATADOG_RUM_APPLICATION_ID,
        clientToken: config.DATADOG_CLIENT_TOKEN,
        service: config.DATADOG_SERVICE,
        options: {
            env: config.DEPLOYMENT_ENV,
            version: `v${config.APP_VERSION}`,
        },
    }));
}

serviceManager.registerService('i18nService', I18nService({
    LOCALE: 'de-DE',
    DEFAULT_LOCALE_TRANSLATIONS: defaultLocaleTranslations,
    LOCALE_RESOURCES: [
        {
            'locale': 'en-US',
            'url': config.ACE_LOCALE_TRANSLATION_EN_US_URL,
        },
        {
            'locale': 'de-DE',
            'url': config.ACE_LOCALE_TRANSLATION_DE_DE_URL,
        },
    ],
}));

serviceManager.registerService('keycloakAuthService', new OpenIDConnectClient({
    authServiceId: 'keycloak',
    serviceURL: config.ACE_KEYCLOAK_SERVICE_URL,
    realm: config.ACE_KEYCLOAK_REALM,
    clientId: config.ACE_KEYCLOAK_CLIENT_ID,
    cookieDomain: config.COOKIE_DOMAIN,
}));

serviceManager.registerService('arcGISAuthService', new OAuth2Client({
    authServiceId: 'arcgis',
    serviceURL: config.ARCGIS_OAUTH2_SERVICE_URL,
    clientId: config.ARCGIS_OAUTH2_CLIENT_ID,
    cookieDomain: config.COOKIE_DOMAIN,
}));

serviceManager.registerService('userProfileService', new MSClientECSUserProfileEUP({
    SERVICE_URL: config.ACE_USER_PROFILE_EUP_SERVICE_URL,
}));

serviceManager.registerService('ecsFlowService', new MSClientECSFlowEF({
    SERVICE_URL: config.ACE_ECS_FLOW_EF_SERVICE_URL,
}));

serviceManager.registerService('partnerManagementService', new MSClientACEPartnerManagementAPM({
    SERVICE_URL: config.ACE_PARTNER_MANAGEMENT_APM_SERVICE_URL,
}));

const arcGISRESTService = new ArcGISRESTClient();
serviceManager.registerService('arcGISRESTService', arcGISRESTService);

serviceManager.registerService('arcGISMapService', new ArcGISMapService({
    serviceURL: config.ARCGIS_SERVICE_URL,
    arcGISRESTService,
}));

serviceManager.registerService('apmFileAssetsService', new EUAFileAssetsService({
    SERVICE_ENDPOINT: config.ACE_APM_FILE_ASSETS_SERVICE_ENDPOINT,
}));

serviceManager.registerService('leaAssignmentFlowService', new MSClientLEAAssignmentFlowALF({
    SERVICE_URL: config.ACE_LEA_ASSIGNMENT_FLOW_ALF_SERVICE_URL,
}));

export default serviceManager;
