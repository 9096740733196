import {take, put, fork, select} from 'redux-saga/effects';
import {ACEPartner, apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import {closeModal, openModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';

const updateStandbyTimesFlow = function* updateEmergencyLawyersStandbyTimesFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(serviceProviderActionTypes.INITIATE_UPDATE_STANDBY_TIMES_FLOW);
        const {standbyTimesId} = payload;

        yield* openModal(modalIds.STANDBY_TIMES_DATA_MODAL, {standbyTimesId: `${standbyTimesId}`});

        const chosenModalOption = yield take([
            serviceProviderActionTypes.CONFIRM_UPDATE_STANDBY_TIMES_ENTRY,
            serviceProviderActionTypes.DECLINE_UPDATE_STANDBY_TIMES_ENTRY,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === serviceProviderActionTypes.CONFIRM_UPDATE_STANDBY_TIMES_ENTRY) {
            const {updatedServiceProviderData, serviceProviderToBeRemoved} = chosenModalOption.payload;
            const params = [];
            if (updatedServiceProviderData) {
                params.push({
                    acePartnerData: {
                        ...ACEPartner.objectToPatchDTO(updatedServiceProviderData.serviceProviderData),
                        partnerType: apmACEPartnerTypes.SERVICE_PROVIDER,
                    },
                    acePartnerId: updatedServiceProviderData.serviceProviderId,
                });
            }
            if (serviceProviderToBeRemoved) {
                params.push({
                    acePartnerData: {
                        ...ACEPartner.objectToPatchDTO(serviceProviderToBeRemoved.serviceProviderData),
                        partnerType: apmACEPartnerTypes.SERVICE_PROVIDER,
                    },
                    acePartnerId: serviceProviderToBeRemoved.serviceProviderId,
                });
            }
            yield fork(
                fetchRequest,
                serviceProviderActionTypes.UPDATE_STANDBY_TIMES_REQUEST,
                partnerManagementService.batchUpdateACEPartner,
                {
                    params,
                },
            );

            const responseAction = yield take([
                serviceProviderActionTypes.UPDATE_STANDBY_TIMES_REQUEST_SUCCEEDED,
                serviceProviderActionTypes.UPDATE_STANDBY_TIMES_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {acePartnerDTOs: serviceProviderDTOs} = response;

                yield put({
                    type: serviceProviderActionTypes.STORE_SERVICE_PROVIDERS,
                    payload: {serviceProviderDTOs},
                });
            }
        }

        yield* closeModal(modalIds.STANDBY_TIMES_DATA_MODAL, {standbyTimesId: `${standbyTimesId}`});
    }
};

export default updateStandbyTimesFlow;
