import {fork, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {QualityManagementFeedback} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const createQualityReportFlow = function* createQualityReportFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        yield take(contractPartnerActionTypes.INITIATE_CREATE_QUALITY_REPORT_FLOW);

        yield* openModal(modalIds.CP_CREATE_QUALITY_REPORT);

        const chosenModalOption = yield take([
            contractPartnerActionTypes.CONFIRM_CREATE_QUALITY_REPORT,
            contractPartnerActionTypes.DECLINE_CREATE_QUALITY_REPORT,
        ]);

        if (chosenModalOption.type === contractPartnerActionTypes.DECLINE_CREATE_QUALITY_REPORT) {
            yield* closeModal(modalIds.CP_CREATE_QUALITY_REPORT);
            continue;
        }

        const {qualityReportData} = chosenModalOption.payload;

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST,
            partnerManagementService.createContractPartnerQualityReport,
            {
                qualityReportData: QualityManagementFeedback.objectToDTO(qualityReportData),
            },
        );

        const responseAction = yield take([
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_FAILED,
        ]);

        if (responseAction.error) {
            yield* closeModal(modalIds.CP_CREATE_QUALITY_REPORT);
            continue;
        }

        yield* closeModal(modalIds.CP_CREATE_QUALITY_REPORT);
    }
};

export default createQualityReportFlow;
