import {take, put, fork, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {ACEPartner} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as commissionerActionTypes from '../commissionerActionTypes';

/**
 * Commissioner create flow (uses modal)
 * creates a new commissioner on the APM
 */
const createCommissionerFlow = function* createCommissionerFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        yield take(commissionerActionTypes.INITIATE_CREATE_COMMISSIONER_FLOW);

        yield* openModal(modalIds.CREATE_COMMISSIONER);

        const chosenModalOption = yield take([
            commissionerActionTypes.CONFIRM_CREATE_COMMISSIONER,
            commissionerActionTypes.DECLINE_CREATE_COMMISSIONER,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === commissionerActionTypes.CONFIRM_CREATE_COMMISSIONER) {
            const commissionerData = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                commissionerActionTypes.CREATE_COMMISSIONER_REQUEST,
                partnerManagementService.createACEPartner,
                {
                    acePartnerDTO: ACEPartner.objectToDTO(commissionerData),
                },
            );
            const responseAction = yield take([
                commissionerActionTypes.CREATE_COMMISSIONER_REQUEST_SUCCEEDED,
                commissionerActionTypes.CREATE_COMMISSIONER_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {acePartnerDTO: commissionerDTO} = response;

                yield put({
                    type: commissionerActionTypes.STORE_COMMISSIONERS,
                    payload: {commissionerDTOs: [commissionerDTO]},
                });
            }
        }

        yield* closeModal(modalIds.CREATE_COMMISSIONER);
    }
};

export default createCommissionerFlow;
