/**
 * Command action types
 */
export const SEARCH_USERS = '[user-profile] SEARCH_USERS';
export const INITIATE_EDIT_USER_PROFILE_FLOW = '[user-profile] INITIATE_EDIT_USER_PROFILE_FLOW';
export const INITIATE_CREATE_USER_PROFILE_FLOW = '[user-profile] INITIATE_CREATE_USER_PROFILE_FLOW';
export const CONFIRM_EDIT_USER_PROFILE = '[user-profile] CONFIRM_EDIT_USER_PROFILE';
export const DECLINE_EDIT_USER_PROFILE = '[user-profile] DECLINE_EDIT_USER_PROFILE';
export const CONFIRM_CREATE_USER_PROFILE = '[user-profile] CONFIRM_CREATE_USER_PROFILE';
export const DECLINE_CREATE_USER_PROFILE = '[user-profile] DECLINE_CREATE_USER_PROFILE';
export const CHANGE_USER_ENABLED_STATE = '[user-profile] CHANGE_USER_ENABLED_STATE';
export const SEARCH_SERVICE_CASES = '[user-profile] SEARCH_SERVICE_CASES';
export const UNLOCK_SERVICE_CASE = '[user-profile] UNLOCK_SERVICE_CASE';

/**
 * Event action types
 */
export const FETCH_USER_PROFILE_REQUEST = '[user-profile] FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_REQUEST_SENT = '[user-profile] FETCH_USER_PROFILE_REQUEST_SENT';
export const FETCH_USER_PROFILE_REQUEST_SUCCEEDED = '[user-profile] FETCH_USER_PROFILE_REQUEST_SUCCEEDED';
export const FETCH_USER_PROFILE_REQUEST_FAILED = '[user-profile] FETCH_USER_PROFILE_REQUEST_FAILED';

export const SEARCH_USERS_REQUEST = '[user-profile] SEARCH_USERS_REQUEST';
export const SEARCH_USERS_REQUEST_SENT = '[user-profile] SEARCH_USERS_REQUEST_SENT';
export const SEARCH_USERS_REQUEST_SUCCEEDED = '[user-profile] SEARCH_USERS_REQUEST_SUCCEEDED';
export const SEARCH_USERS_REQUEST_FAILED = '[user-profile] SEARCH_USERS_REQUEST_FAILED';

export const FETCH_USER_DETAILS_REQUEST = '[user-profile] FETCH_USER_DETAILS_REQUEST';
export const FETCH_USER_DETAILS_REQUEST_SENT = '[user-profile] FETCH_USER_DETAILS_REQUEST_SENT';
export const FETCH_USER_DETAILS_REQUEST_SUCCEEDED = '[user-profile] FETCH_USER_DETAILS_REQUEST_SUCCEEDED';
export const FETCH_USER_DETAILS_REQUEST_FAILED = '[user-profile] FETCH_USER_DETAILS_REQUEST_FAILED';

export const UPDATE_USER_PROFILE_REQUEST = '[user-profile] UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_REQUEST_SENT = '[user-profile] UPDATE_USER_PROFILE_REQUEST_SENT';
export const UPDATE_USER_PROFILE_REQUEST_SUCCEEDED = '[user-profile] UPDATE_USER_PROFILE_REQUEST_SUCCEEDED';
export const UPDATE_USER_PROFILE_REQUEST_FAILED = '[user-profile] UPDATE_USER_PROFILE_REQUEST_FAILED';

export const CHANGE_USER_ENABLED_STATE_REQUEST = '[user-profile] CHANGE_USER_ENABLED_STATE_REQUEST';
export const CHANGE_USER_ENABLED_STATE_REQUEST_SENT = '[user-profile] CHANGE_USER_ENABLED_STATE_REQUEST_SENT';
export const CHANGE_USER_ENABLED_STATE_REQUEST_SUCCEEDED = '[user-profile] CHANGE_USER_ENABLED_STATE_REQUEST_SUCCEEDED';
export const CHANGE_USER_ENABLED_STATE_REQUEST_FAILED = '[user-profile] CHANGE_USER_ENABLED_STATE_REQUEST_FAILED';

export const FETCH_LOCKED_SERVICE_CASES = '[user-profile] FETCH_LOCKED_SERVICE_CASES';
export const FETCH_LOCKED_SERVICE_CASES_REQUEST_SENT = '[user-profile] FETCH_LOCKED_SERVICE_CASES_REQUEST_SENT';
export const FETCH_LOCKED_SERVICE_CASES_REQUEST_SUCCEEDED = '[user-profile] FETCH_LOCKED_SERVICE_CASES_REQUEST_SUCCEEDED';
export const FETCH_LOCKED_SERVICE_CASES_REQUEST_FAILED = '[user-profile] FETCH_LOCKED_SERVICE_CASES_REQUEST_FAILED';

export const UNLOCK_SERVICE_CASE_REQUEST = '[user-profile] UNLOCK_SERVICE_CASE_REQUEST';
export const UNLOCK_SERVICE_CASE_REQUEST_SENT = '[user-profile] UNLOCK_SERVICE_CASE_REQUEST_SENT';
export const UNLOCK_SERVICE_CASE_REQUEST_SUCCEEDED = '[user-profile] UNLOCK_SERVICE_CASE_REQUEST_SUCCEEDED';
export const UNLOCK_SERVICE_CASE_REQUEST_FAILED = '[user-profile] UNLOCK_SERVICE_CASE_REQUEST_FAILED';

export const CREATE_USER_PROFILE_REQUEST = '[user-profile] CREATE_USER_PROFILE_REQUEST';
export const CREATE_USER_PROFILE_REQUEST_SENT = '[user-profile] CREATE_USER_PROFILE_REQUEST_SENT';
export const CREATE_USER_PROFILE_REQUEST_SUCCEEDED = '[user-profile] CREATE_USER_PROFILE_REQUEST_SUCCEEDED';
export const CREATE_USER_PROFILE_REQUEST_FAILED = '[user-profile] CREATE_USER_PROFILE_REQUEST_FAILED';

export const FETCH_USER_PROFILES_REQUEST = '[user-profile] FETCH_USER_PROFILES_REQUEST';
export const FETCH_USER_PROFILES_REQUEST_SENT = '[user-profile] FETCH_USER_PROFILES_REQUEST_SENT';
export const FETCH_USER_PROFILES_REQUEST_SUCCEEDED = '[user-profile] FETCH_USER_PROFILES_REQUEST_SUCCEEDED';
export const FETCH_USER_PROFILES_REQUEST_FAILED = '[user-profile] FETCH_USER_PROFILES_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_USER_PROFILE = '[user-profile] STORE_USER_PROFILE';
export const STORE_USER_PROFILES = '[user-profile] STORE_USER_PROFILES';
export const STORE_USER_SEARCH_RESULTS = '[user-profile] STORE_USER_SEARCH_RESULTS';
export const STORE_USER_DETAILS = '[user-profile] STORE_USER_DETAILS';
export const SET_USER_ENABLED_STATE = '[user-profile] SET_USER_ENABLED_STATE';
export const STORE_LOCKED_SERVICE_CASE_RESULTS = '[user-profile] STORE_LOCKED_SERVICE_CASE_RESULTS';
export const SET_LOCKED_SERVICE_CASE_RESULT = '[user-profile] SET_LOCKED_SERVICE_CASE_RESULT';
