import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {resolveRoute} from '@computerrock/formation-router';
import {apmQualityManagementFeedbackStatusTypes, apmQualityManagementFeedbackChannelTypes, efRejectionReasonTypes} from '@ace-de/eua-entity-types';
import {useStyles, ButtonPrimary, Divider, Modal, TextAreaField, List, HighlightCircle} from '@ace-de/ui-components';
import {Icon, closeIcon, shareIcon, checkmarkIcon, InteractiveIcon, ratingNegativeIcon, ratingPositiveIcon, arrowDownIcon, arrowUpIcon, plusIcon, deleteIcon, warningIcon, acceptedIcon} from '@ace-de/ui-components/icons';
import {Form, InputField} from '@ace-de/ui-components/form';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import config from '../../config';
import styles from './QualityManagementNoteQualificationModal.module.scss';
import routePaths from '../../routePaths';
import {validateEmail} from '../../utils/validation';
import formatECSServiceCaseScreenURL from '../../utils/formatECSServiceCaseScreenURL';

const QualityManagementNoteQualificationModal = props => {
    const {cx} = useStyles(props, styles);
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('quality_management_note_qualification_modal');
    const {hasBackdrop, contractPartners, feedback, justifyQualityManagementFeedback} = props;
    const {confirmQMNoteQualification, declineQMNoteQualification, isQMQualificationNoteForwarded} = props;
    const {submitQMNoteQualificationEmailForm, resetQMQualificationNoteForwardingStatus} = props;
    const [comment, setComment] = useState('');
    const [isEmailFormOpened, setIsEmailFormOpened] = useState(false);
    const [emailAddresses, setEmailAddresses] = useState([]);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [areEmailRecipientsEmpty, setAreEmailRecipientsEmpty] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [emailMessage, setEmailMessage] = useState('');

    const handleCloseModal = () => {
        declineQMNoteQualification();
    };

    const handleOnSubmit = () => {
        if (!comment) return;
        confirmQMNoteQualification({comment});
    };

    const justifiedFeedback = justification => {
        justifyQualityManagementFeedback({
            justification,
            comment,
        });
    };

    const openContractPartnerScreen = contractPartnerId => {
        const {pathname} = resolveRoute(routePaths.CONTRACT_PARTNER, {contractPartnerId});
        window.open(pathname, '_blank');
    };

    const toggleEmailForm = () => {
        if (isQMQualificationNoteForwarded) {
            resetQMQualificationNoteForwardingStatus({isQMQualificationNoteForwarded: false});
            setEmailAddresses([]);
            setEmailAddress('');
            setEmailMessage('');
        }
        setIsEmailFormOpened(!isEmailFormOpened);
    };

    const addEmailAddress = formValues => {
        setAreEmailRecipientsEmpty(false);
        if (!validateEmail((formValues.newEmailAddress))) {
            setIsEmailValid(false);
            return;
        }
        const newEmailAddresses = emailAddresses;
        if (!emailAddresses.find(emailAddress => emailAddress === formValues.newEmailAddress)) {
            newEmailAddresses.push(formValues.newEmailAddress);
        }
        setEmailAddresses([...newEmailAddresses]);
        setEmailAddress('');
        setIsEmailValid(true);
    };

    const handleOnEmailFormSubmit = formValues => {
        if (!emailAddresses.length) {
            setAreEmailRecipientsEmpty(true);
            return;
        }

        submitQMNoteQualificationEmailForm({
            data: {
                emails: emailAddresses,
                message: formValues.emailMessage,
            },
            feedbackId: feedback.id,
        });
    };

    // if no feedback, don't render
    if (!feedback) return null;
    const {caseId, positiveFeedback, categories, comments, contractPartnerId, restrictionStartDateTime} = feedback;
    const {reportedBy, createdAt, description, justification, restrictionEndDateTime, createdByUser} = feedback;
    const contractPartnerName = contractPartners
        ? Object.values(contractPartners).find(cp => cp.id === contractPartnerId)?.name : '';

    return (
        <Modal
            title={translateModal('header.title')}
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={handleCloseModal}
                />
            )}
            contentClassName={cx(['ace-c-modal__content--scrollable', 'global!ace-u-modal-content-size--m'])}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-full-width',
                    'global!ace-u-margin--top-32',
                ])}
            >
                <Divider className={cx('global!ace-u-margin--bottom-32')} />
                <div className={cx('global!ace-u-flex', 'global!ace-u-flex--column-gap-24')}>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-column',
                            'global!ace-u-flex--basis-25',
                        ])}
                    >
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-8',
                            ])}
                        >
                            {translateModal('section_header.case_number')}
                        </p>
                        {caseId ? (
                            <a
                                href={formatECSServiceCaseScreenURL(caseId)}
                                className={cx([
                                    'global!ace-u-margin--bottom-24',
                                    'global!ace-u-typography--variant-body',
                                    'global!ace-u-typography--color-highlighted',
                                ])}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {caseId}
                            </a>
                        ) : ''}
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-8',
                            ])}
                        >
                            {translateModal('section_header.contract_partner')}
                        </p>
                        <p
                            className={cx([
                                'global!ace-u-margin--bottom-24',
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-typography--color-highlighted',
                                'global!ace-u-cursor--pointer',
                            ])}
                            onClick={() => openContractPartnerScreen(contractPartnerId)}
                        >
                            {contractPartnerName || ''}
                        </p>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-8',
                            ])}
                        >
                            {translateModal('section_header.category')}
                        </p>
                        {categories?.map(category => (
                            <span
                                key={category}
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-margin--bottom-24',
                                    'global!ace-u-typography--variant-body',
                                    'global!ace-u-flex--align-center',
                                ])}
                            >
                                <Icon
                                    icon={positiveFeedback ? ratingPositiveIcon : ratingNegativeIcon}
                                    className={cx([
                                        'global!ace-u-margin--right-8',
                                        'global!ace-u-flex--shrink-0',
                                    ])}
                                />
                                {(Object.values(efRejectionReasonTypes).includes(category)
                                    ? translate('global.quality_management_feedback_channel.rejected') + ' '
                                    : ''
                                ) + translate(`global.quality_management_feedback_category.${category.toLowerCase()}`)}
                            </span>
                        ))}
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-8',
                            ])}
                        >
                            {translateModal('section_header.created_by')}
                        </p>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body',
                            ])}
                        >
                            {createdByUser}
                        </p>
                        <p
                            className={cx([
                                'global!ace-u-margin--bottom-24',
                                'global!ace-u-typography--variant-body',
                            ])}
                        >
                            {`${moment(createdAt).format('DD.MM.YYYY HH:mm')}
                            ${translate('global.time_unit.time')}`}
                        </p>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-8',
                            ])}
                        >
                            {translateModal('section_header.reported_by')}
                        </p>
                        <p
                            className={cx('global!ace-u-typography--variant-body')}
                        >
                            {reportedBy || ''}
                        </p>
                        {!!restrictionStartDateTime && (
                            <Fragment>
                                <p
                                    className={cx([
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-margin--top-24',
                                        'global!ace-u-margin--bottom-8',
                                    ])}
                                >
                                    {translateModal('section_header.start_date_and_time')}
                                </p>
                                <p
                                    className={cx([
                                        'global!ace-u-margin--bottom-24',
                                        'global!ace-u-typography--variant-body',
                                    ])}
                                >
                                    {moment(restrictionStartDateTime).isValid()
                                        ? `${moment(restrictionStartDateTime).format('DD.MM.YYYY HH:mm')} ${translate('global.time_unit.time')}`
                                        : ''}
                                </p>
                            </Fragment>
                        )}
                        {!!restrictionEndDateTime && (
                            <Fragment>
                                <p
                                    className={cx([
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-margin--bottom-8',
                                    ])}
                                >
                                    {translateModal('section_header.end_date_and_time')}
                                </p>
                                <p
                                    className={cx('global!ace-u-typography--variant-body')}
                                >
                                    {moment(restrictionEndDateTime).isValid()
                                        ? `${moment(restrictionEndDateTime).format('DD.MM.YYYY HH:mm')} ${translate('global.time_unit.time')}`
                                        : ''}
                                </p>
                            </Fragment>
                        )}
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-column',
                            'global!ace-u-height--full',
                            'global!ace-u-max-height--full',
                            'global!ace-u-flex--basis-75',
                        ])}
                    >
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-8',
                            ])}
                        >
                            {translateModal('section_header.description')}
                        </p>
                        <p
                            className={cx([
                                'global!ace-u-margin--bottom-32',
                                'global!ace-u-typography--variant-body',
                            ])}
                        >
                            {(feedback?.rank && feedback?.qualityManagementFeedbackChannel
                             && feedback.qualityManagementFeedbackChannel === apmQualityManagementFeedbackChannelTypes.REJECTED // eslint-disable-line max-len
                                ? `${translate('global.qm_note_description.ranked')}${feedback.rank} ` : ''
                            ) + description}
                        </p>
                        {comments.length > 0 && (
                            <List
                                className={cx('global!ace-u-full-width', 'ace-c-note-qualification-modal--list')}
                            >
                                {comments.map(comment => {
                                    return (
                                        <div className={cx('global!ace-u-margin--bottom-48')} key={comment.createdAt}>
                                            <p
                                                className={cx([
                                                    'global!ace-u-typography--variant-caption',
                                                    'global!ace-u-margin--bottom-8',
                                                ])}
                                            >
                                                {comment.createdByUser}, {moment(comment.createdAt).format('DD.MM.YYYY HH:mm')}
                                            </p>
                                            <span
                                                className={cx([
                                                    'global!ace-u-margin--bottom-24',
                                                    'global!ace-u-typography--variant-body',
                                                ])}
                                            >
                                                {comment.comment}
                                            </span>
                                        </div>
                                    );
                                })}
                                <Divider
                                    className={cx('global!ace-u-margin--bottom-8')}
                                />
                            </List>
                        )}
                        <TextAreaField
                            className={cx('global!ace-u-full-width', 'global!ace-u-margin--bottom-8')}
                            name="comment"
                            value={comment}
                            onChange={value => setComment(value)}
                            label={translateModal('input_label.comment')}
                            placeholder={translateModal('input_placeholder.comment')}
                            maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                            isResizable={false}
                        />
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-space-between',
                                'global!ace-u-flex--align-flex-end',
                            ])}
                        >
                            <InteractiveIcon
                                icon={!isEmailFormOpened ? arrowDownIcon : arrowUpIcon}
                                className={cx([
                                    'ace-c-interactive-icon--reverse',
                                    'ace-c-interactive-icon--primary',
                                ])}
                                onClick={toggleEmailForm}
                            >
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-flex--justify-space-between',
                                    ])}
                                >
                                    <Icon icon={shareIcon} />
                                    <p className={cx('global!ace-u-margin--left-8')}>
                                        {translateModal('forward').toUpperCase()}
                                    </p>
                                </div>
                            </InteractiveIcon>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-flex--align-flex-end',
                                ])}
                            >
                                <ButtonPrimary
                                    name="submitComment"
                                    onClick={handleOnSubmit}
                                    isDisabled={!comment}
                                >
                                    {translateModal('button_label.save')}
                                </ButtonPrimary>
                                {justification === apmQualityManagementFeedbackStatusTypes.OPEN
                                        && (
                                        <div className={cx('global!ace-u-flex', 'global!ace-u-flex--justify-flex-end')}>
                                            <ButtonPrimary
                                                name="submitJustified"
                                                className={cx([
                                                    'global!ace-u-margin--32-24-0-32',
                                                    'global!ace-u-margin--right-24',
                                                    'ace-c-button-primary--is-positive',
                                                ])}
                                                onClick={() => justifiedFeedback(
                                                    apmQualityManagementFeedbackStatusTypes.JUSTIFIED,
                                                )}
                                            >
                                                <Icon
                                                    icon={checkmarkIcon}
                                                    className={cx([
                                                        'ace-c-icon--color-contrast',
                                                        'global!ace-u-margin--right-8',
                                                    ])}
                                                />
                                                {translateModal('button_label.justified')}
                                            </ButtonPrimary>
                                            <ButtonPrimary
                                                name="submitUnjustified"
                                                className={cx([
                                                    'global!ace-u-margin--32-0-0-32',
                                                    'ace-c-button-primary--is-negative',
                                                ])}
                                                onClick={() => justifiedFeedback(
                                                    apmQualityManagementFeedbackStatusTypes.NOT_JUSTIFIED,
                                                )}
                                            >
                                                <Icon
                                                    icon={closeIcon}
                                                    className={cx([
                                                        'ace-c-icon--color-contrast',
                                                        'global!ace-u-margin--right-8',
                                                    ])}
                                                />
                                                {translateModal('button_label.unjustified')}
                                            </ButtonPrimary>
                                        </div>
                                        )}
                            </div>
                        </div>
                        <Divider
                            className={cx([
                                'global!ace-u-margin--top-16',
                                'global!ace-u-margin--bottom-32',
                            ])}
                        />
                        {isEmailFormOpened && !isQMQualificationNoteForwarded && (
                            <Form name="emailForm" onSubmit={handleOnEmailFormSubmit}>
                                <div
                                    className={cx('global!ace-u-grid')}
                                >
                                    <div className={cx('global!ace-u-grid-column--span-12')}>
                                        <div
                                            className={cx([
                                                'global!ace-u-margin--bottom-16',
                                                'global!ace-u-width--full',
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-flex-end',
                                            ])}
                                        >
                                            <Form name="emailAddressForm" onSubmit={addEmailAddress}>
                                                <InputField
                                                    name="newEmailAddress"
                                                    className={cx('global!ace-u-flex--basis-50')}
                                                    label={translateModal('input_label.email')}
                                                    placeholder={translateModal('input_placeholder.email')}
                                                    value={emailAddress}
                                                    isInvalid={!isEmailValid}
                                                    onChange={value => setEmailAddress(value)}
                                                />
                                                <ButtonPrimary
                                                    className={cx('global!ace-u-flex--basis-5')}
                                                    type="submit"
                                                >
                                                    <Icon
                                                        icon={plusIcon}
                                                        className={cx('ace-c-icon--color-contrast')}
                                                    />
                                                </ButtonPrimary>
                                            </Form>
                                        </div>
                                        {(!isEmailValid || areEmailRecipientsEmpty) && (
                                        <p
                                            className={cx([
                                                'global!ace-u-typography--color-warning',
                                                'global!ace-u-typography--variant-caption',
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--align-center',
                                            ])}
                                        >
                                            <Icon
                                                icon={warningIcon}
                                                className={cx([
                                                    'global!ace-u-margin--right-8',
                                                    'ace-c-icon--s',
                                                    'ace-c-icon--color-warning',
                                                ])}
                                            />
                                            <span>
                                                {areEmailRecipientsEmpty
                                                    ? translateModal('input_error.empty_email')
                                                    : translateModal('input_error.email')}
                                            </span>
                                        </p>
                                        )}
                                        {emailAddresses.length > 0 && emailAddresses.map((email, index) => (
                                            <div
                                                key={index}
                                                className={cx([
                                                    'global!ace-u-flex',
                                                    'global!ace-u-margin--top-4',
                                                ])}
                                            >
                                                <Icon
                                                    icon={deleteIcon}
                                                    onClick={() => setEmailAddresses(prevState => {
                                                        return [...prevState.filter(email => (
                                                            prevState.indexOf(email) !== index))];
                                                    })}
                                                />
                                                <p>{email}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <TextAreaField
                                        name="emailMessage"
                                        label={translateModal('input_label.message')}
                                        maxLength={config.MAXIMUM_QMN_DESCRIPTION_TEXT_AREA_CONTENT_LENGTH}
                                        isResizable={false}
                                        className={cx('global!ace-u-grid-column--span-9')}
                                        value={emailMessage}
                                        onChange={value => setEmailMessage(value)}
                                    />
                                    <div
                                        className={cx([
                                            'global!ace-u-grid-column--span-9',
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--justify-flex-end',
                                        ])}
                                    >
                                        <ButtonPrimary type="submit">
                                            {translateModal('button_label.send')}
                                        </ButtonPrimary>
                                    </div>
                                </div>
                            </Form>
                        )}
                        {isQMQualificationNoteForwarded && (
                            <div
                                className={cx([
                                    'global!ace-u-margin--bottom-64',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-flex--justify-center',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-full-width',
                                ])}
                            >
                                <HighlightCircle
                                    className={cx([
                                        'ace-c-highlight-circle--medium',
                                        'global!ace-u-margin--bottom-32',
                                        'ace-c-highlight-circle--success',
                                    ])}
                                >
                                    <Icon
                                        icon={acceptedIcon}
                                        className={cx([
                                            'ace-c-icon--xxl',
                                            'ace-c-icon--color-success',
                                        ])}
                                    />
                                </HighlightCircle>
                                <h3 className={cx('global!ace-u-typography--variant-h3')}>
                                    {translateModal('success_message.title')}
                                </h3>
                                <p
                                    className={cx([
                                        'global!ace-u-margin--top-32',
                                        'global!ace-u-typography--align-center',
                                        'global!ace-u-typography--variant-body',
                                    ])}
                                >
                                    {translateModal('success_message.text')}
                                </p>
                            </div>
                        )}
                        {justification !== apmQualityManagementFeedbackStatusTypes.OPEN && (
                            <div>
                                <p
                                    className={cx([
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-margin--bottom-8',
                                    ])}
                                >
                                    {translateModal('section_header.qualification')}
                                </p>
                                <p
                                    className={cx([
                                        'global!ace-u-margin--bottom-24',
                                        'global!ace-u-typography--variant-body',
                                    ])}
                                >
                                    {justification === apmQualityManagementFeedbackStatusTypes.JUSTIFIED
                                        ? translate('global.qualification.justified')
                                        : translate('global.qualification.unjustified')}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
QualityManagementNoteQualificationModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    contractPartners: PropTypes.object,
    feedback: PropTypes.object,
    isQMQualificationNoteForwarded: PropTypes.bool,
    declineQMNoteQualification: PropTypes.func.isRequired,
    confirmQMNoteQualification: PropTypes.func.isRequired,
    justifyQualityManagementFeedback: PropTypes.func.isRequired,
    submitQMNoteQualificationEmailForm: PropTypes.func.isRequired,
    resetQMQualificationNoteForwardingStatus: PropTypes.func.isRequired,
};

QualityManagementNoteQualificationModal.defaultProps = {
    hasBackdrop: true,
    contractPartners: null,
    feedback: null,
    isQMQualificationNoteForwarded: false,
};

const mapStateToProps = (state, props) => {
    const feedbackSelector = contractPartnerSelectors.createQualityManagementFeedbackSelector();
    return {
        feedback: feedbackSelector(state, props),
        contractPartners: contractPartnerSelectors.getContractPartners(state),
        isQMQualificationNoteForwarded: state.contractPartners.isQMQualificationNoteForwarded,
    };
};

const mapDispatchToProps = dispatch => ({
    confirmQMNoteQualification: payload => dispatch({
        type: contractPartnerActionTypes.CONFIRM_CREATE_QM_NOTE_QUALIFICATION,
        payload,
    }),
    declineQMNoteQualification: payload => dispatch({
        type: contractPartnerActionTypes.DECLINE_CREATE_QM_NOTE_QUALIFICATION,
        payload,
    }),
    justifyQualityManagementFeedback: payload => dispatch({
        type: contractPartnerActionTypes.JUSTIFY_QUALITY_MANAGEMENT_FEEDBACK,
        payload,
    }),
    submitQMNoteQualificationEmailForm: payload => dispatch({
        type: contractPartnerActionTypes.SUBMIT_QM_NOTE_QUALIFICATION_EMAIL_FORM,
        payload,
    }),
    resetQMQualificationNoteForwardingStatus: payload => dispatch({
        type: contractPartnerActionTypes.STORE_QM_QUALIFICATION_NOTE_FORWARDING_STATUS,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(QualityManagementNoteQualificationModal);
