import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, Form, Input, InputField, ButtonPrimary, Autocomplete, Option} from '@ace-de/ui-components';
import {InteractiveIcon, editIcon, closeIcon, arrowDownIcon} from '@ace-de/ui-components/icons';
import {europeanCountries} from '@ace-de/eua-entity-types';
import * as commissionerActionTypes from '../commissionerActionTypes';
import {validateEmail} from '../../utils/validation';

const nonMandatoryFields = ['websiteUrl', 'faxNo', 'street', 'postCode', 'city', 'country', 'phoneNo'];
const initialErrorFields = {
    name: [],
    email: [],
};

const CommissionerBasicDataPanel = ({commissioner, submitCommissionerForm}) => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translatePanel = createTranslateShorthand('commissioner_basic_data_panel');
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const [commissionerCountry, setCommissionerCountry] = useState(
        commissioner?.address?.country
            ? commissioner.address.country
            : '',
    );
    const sortedCountries = Object.entries(europeanCountries)
        .map(([countryCode, country]) => [
            countryCode,
            {id: country.id, name: translate(`global.country.${snakeCase(country.name)}`)},
        ]).sort(([countryCodeA, countryA], [countryCodeB, countryB]) => {
            return countryA.name.localeCompare(countryB.name);
        });
    const [errorFields, setErrorFields] = useState({...initialErrorFields});

    useEffect(() => {
        if (commissioner?.address?.country) {
            setCommissionerCountry(commissioner.address.country);
        }
    }, [commissioner?.address?.country]);

    const handleFormFieldsValidation = formValues => {
        let hasError = false;
        const mandatoryErrorMessage = translatePanel('field_cannot_be_empty');
        const emailErrorMessage = translatePanel('please_enter_valid_email');

        Object.entries(formValues).forEach(formValueEntry => {
            const key = formValueEntry[0];
            const value = formValueEntry[1];

            if (value === '' && !nonMandatoryFields.includes(key)) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [mandatoryErrorMessage],
                }));
                hasError = true;
            } else if (key === 'email' && !validateEmail(value)) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [emailErrorMessage],
                }));
                hasError = true;
            } else if (errorFields[key]) {
                setErrorFields(prevState => ({
                    ...prevState,
                    [key]: [],
                }));
            }
        });

        return hasError;
    };

    const toggleEditMode = () => {
        setIsEditModeActive(prevState => !prevState);
        if (isEditModeActive) setErrorFields({...initialErrorFields});
    };

    const handleOnSubmit = formValues => {
        const hasError = handleFormFieldsValidation(formValues);
        if (hasError || !formValues) return;
        const {street, city, postCode, phoneNo, email, faxNo, websiteUrl} = formValues;

        const commissionerData = {
            ...formValues,
            address: {
                street,
                city,
                postCode,
                country: commissionerCountry,
            },
            businessContactDetails: {
                phoneNo,
                email,
                faxNo,
                websiteUrl,
            },
        };

        submitCommissionerForm({
            commissionerData,
            commissionerId: commissioner.id,
        });

        setIsEditModeActive(false);
    };

    if (!commissioner) return null;
    const {address, businessContactDetails} = commissioner;

    return (
        <Panel
            title={translatePanel('panel_title.address_data')}
            actions={!isEditModeActive ? (
                <InteractiveIcon
                    icon={editIcon}
                    onClick={toggleEditMode}
                />
            ) : (
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={toggleEditMode}
                />
            )}
        >
            {!isEditModeActive ? (
                <div className={cx('global!ace-u-two-column-layout')}>
                    <div>
                        <DataRow
                            label={translatePanel('data_row_label.name')}
                            qaIdent="commissioner-name"
                        >
                            {commissioner.name || ''}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.street')}
                            qaIdent="commissioner-street"
                        >
                            {address?.street || ''}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.post_code')}
                            qaIdent="commissioner-post-code"
                        >
                            {address?.postCode || ''}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.city')}
                            qaIdent="commissioner-city"
                        >
                            {address?.city || ''}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.country')}
                            qaIdent="commissioner-country"
                        >
                            {address?.country
                                ? (europeanCountries[address.country]?.name
                                    ? translate(`global.country.${snakeCase(europeanCountries[address.country].name)}`)
                                    : address.country)
                                : ''
                            }
                        </DataRow>
                    </div>
                    <div>
                        <DataRow
                            label={translatePanel('data_row_label.phone')}
                            qaIdent="commissioner-phone"
                        >
                            {businessContactDetails?.phoneNo || ''}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.fax')}
                            qaIdent="commissioner-fax"
                        >
                            {businessContactDetails?.faxNo || ''}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.email')}
                            qaIdent="commissioner-email"
                        >
                            {businessContactDetails?.email || ''}
                        </DataRow>
                        <DataRow
                            label={translatePanel('data_row_label.website')}
                            qaIdent="commissioner-website"
                        >
                            {businessContactDetails?.websiteUrl || ''}
                        </DataRow>
                    </div>
                </div>
            ) : (
                <Form name="updateCommissionerData" onSubmit={handleOnSubmit}>
                    <div className={cx('global!ace-u-two-column-layout')}>
                        <div>
                            <DataRow
                                label={translatePanel('data_row_label.name')}
                                qaIdent="commissioner-name"
                                isFieldRequired={true}
                            >
                                <InputField
                                    name="name"
                                    value={commissioner.name || ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                    errors={errorFields.name}
                                />
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.street')}
                                qaIdent="commissioner-street"
                            >
                                <Input
                                    name="street"
                                    value={address?.street || ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                />
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.post_code')}
                                qaIdent="commissioner-post-code"
                            >
                                <Input
                                    name="postCode"
                                    value={address?.postCode || ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                />
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.city')}
                                qaIdent="commissioner-city"
                            >
                                <Input
                                    name="city"
                                    value={address?.city || ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                />
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.country')}
                                qaIdent="commissioner-country"
                            >
                                <Autocomplete
                                    name="country"
                                    value={commissionerCountry || ''}
                                    className={cx(['ace-c-select--small', 'global!ace-u-full-width'])}
                                    onOptionSelect={value => setCommissionerCountry(value)}
                                    icon={arrowDownIcon}
                                >
                                    {sortedCountries
                                        .map(([countryCode, country]) => {
                                            return (
                                                <Option
                                                    key={country.id}
                                                    name={`country-${country.id}`}
                                                    value={countryCode}
                                                    className={cx('ace-c-option--small')}
                                                >
                                                    {country.name}
                                                </Option>
                                            );
                                        })
                                        }
                                </Autocomplete>
                            </DataRow>
                        </div>
                        <div>
                            <DataRow
                                label={translatePanel('data_row_label.phone')}
                                qaIdent="commissioner-phone"
                            >
                                <Input
                                    name="phoneNo"
                                    value={businessContactDetails?.phoneNo || ''}
                                    className={cx('ace-c-input--small')}
                                />
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.fax')}
                                qaIdent="commissioner-fax"
                            >
                                <Input
                                    name="faxNo"
                                    value={businessContactDetails?.faxNo || ''}
                                    className={cx('ace-c-input--small')}
                                />
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.email')}
                                qaIdent="commissioner-email"
                                isFieldRequired={true}
                            >
                                <InputField
                                    name="email"
                                    value={businessContactDetails?.email || ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-full-width'])}
                                    errors={errorFields.email}
                                />
                            </DataRow>
                            <DataRow
                                label={translatePanel('data_row_label.website')}
                                qaIdent="commissioner-website"
                            >
                                <Input
                                    name="websiteUrl"
                                    value={businessContactDetails?.websiteUrl || ''}
                                    className={cx('ace-c-input--small', 'global!ace-u-flex--grow-1')}
                                />
                            </DataRow>
                        </div>
                    </div>
                    <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--justify-flex-end'])}>
                        <ButtonPrimary name="submitCommissionerData" type="submit">
                            {translatePanel('button_label.save')}
                        </ButtonPrimary>
                    </div>
                </Form>
            )}
        </Panel>
    );
};

CommissionerBasicDataPanel.propTypes = {
    commissioner: PropTypes.object,
    submitCommissionerForm: PropTypes.func.isRequired,
};

CommissionerBasicDataPanel.defaultProps = {
    commissioner: null,
};

const mapDispatchToProps = dispatch => ({
    submitCommissionerForm: payload => dispatch({
        type: commissionerActionTypes.SUBMIT_COMMISSIONER_FORM,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(CommissionerBasicDataPanel);
