import {take, select, put, fork} from 'redux-saga/effects';
import {ACEPartner, apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import {closeModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';

const updateServiceProvider = function* updateServiceProvider() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(serviceProviderActionTypes.SUBMIT_SERVICE_PROVIDER_FORM);
        const {serviceProviderId, serviceProviderData} = payload;

        yield fork(
            fetchRequest,
            serviceProviderActionTypes.UPDATE_SERVICE_PROVIDER_REQUEST,
            partnerManagementService.updateACEPartner,
            {
                acePartnerData: {
                    ...ACEPartner.objectToPatchDTO(serviceProviderData),
                    partnerType: apmACEPartnerTypes.SERVICE_PROVIDER,
                },
                acePartnerId: serviceProviderId,
            },
        );

        const responseAction = yield take([
            serviceProviderActionTypes.UPDATE_SERVICE_PROVIDER_REQUEST_SUCCEEDED,
            serviceProviderActionTypes.UPDATE_SERVICE_PROVIDER_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {acePartnerDTO: serviceProviderDTO} = response;

            yield put({
                type: serviceProviderActionTypes.STORE_SERVICE_PROVIDERS,
                payload: {serviceProviderDTOs: [serviceProviderDTO]},
            });
        }

        yield* closeModal(modalIds.DEACTIVATE_SP_CONFIRMATION);
    }
};

export default updateServiceProvider;
