/**
 * Command action types
 */
export const SEARCH_COMMISSIONERS = '[commissioners] SEARCH_COMMISSIONERS';
export const SUBMIT_COMMISSIONER_FORM = '[commissioners] SUBMIT_COMMISSIONER_FORM';
export const INITIATE_CREATE_COMMISSIONER_FLOW = '[commissioners] INITIATE_CREATE_COMMISSIONER_FLOW';
export const CONFIRM_CREATE_COMMISSIONER = '[commissioners] CONFIRM_CREATE_COMMISSIONER';
export const DECLINE_CREATE_COMMISSIONER = '[commissioners] DECLINE_CREATE_COMMISSIONER';

/**
 * Event action types
 */
export const SEARCH_COMMISSIONERS_REQUEST = '[commissioners] SEARCH_COMMISSIONERS_REQUEST';
export const SEARCH_COMMISSIONERS_REQUEST_SENT = '[commissioners] SEARCH_COMMISSIONERS_REQUEST_SENT';
export const SEARCH_COMMISSIONERS_REQUEST_SUCCEEDED = '[commissioners] SEARCH_COMMISSIONERS_REQUEST_SUCCEEDED';
export const SEARCH_COMMISSIONERS_REQUEST_FAILED = '[commissioners] SEARCH_COMMISSIONERS_REQUEST_FAILED';

export const UPDATE_COMMISSIONER_REQUEST = '[commissioners] UPDATE_COMMISSIONER_REQUEST';
export const UPDATE_COMMISSIONER_REQUEST_SENT = '[commissioners] UPDATE_COMMISSIONER_REQUEST_SENT';
export const UPDATE_COMMISSIONER_REQUEST_SUCCEEDED = '[commissioners] UPDATE_COMMISSIONER_REQUEST_SUCCEEDED';
export const UPDATE_COMMISSIONER_REQUEST_FAILED = '[commissioners] UPDATE_COMMISSIONER_REQUEST_FAILED';

export const CREATE_COMMISSIONER_REQUEST = '[commissioners] CREATE_COMMISSIONER_REQUEST';
export const CREATE_COMMISSIONER_REQUEST_SENT = '[commissioners] CREATE_COMMISSIONER_REQUEST_SENT';
export const CREATE_COMMISSIONER_REQUEST_SUCCEEDED = '[commissioners] CREATE_COMMISSIONER_REQUEST_SUCCEEDED';
export const CREATE_COMMISSIONER_REQUEST_FAILED = '[commissioners] CREATE_COMMISSIONER_REQUEST_FAILED';

export const FETCH_COMMISSIONER_REQUEST = '[commissioners] FETCH_COMMISSIONER_REQUEST';
export const FETCH_COMMISSIONER_REQUEST_SENT = '[commissioners] FETCH_COMMISSIONER_REQUEST_SENT';
export const FETCH_COMMISSIONER_REQUEST_SUCCEEDED = '[commissioners] FETCH_COMMISSIONER_REQUEST_SUCCEEDED';
export const FETCH_COMMISSIONER_REQUEST_FAILED = '[commissioners] FETCH_COMMISSIONER_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_COMMISSIONER_SEARCH_RESULTS = '[commissioners] STORE_COMMISSIONER_SEARCH_RESULTS';
export const STORE_COMMISSIONERS = '[commissioners] STORE_COMMISSIONERS';
