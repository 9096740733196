import modalIds from './modalIds';
import NotFoundScreen from './application/NotFoundScreen';
import UserDetailsModal from './user-profiles/modals/UserDetailsModal';
import FetchUserDataFailedWarningModal from './user-profiles/modals/FetchUserDataFailedWarningModal';
import CreateCommissionerModal from './commissioners/modals/CreateCommissionerModal';
import DeactivateSPConfirmationModal from './service-providers/modals/DeactivateSPConfirmationModal';
import StandbyTimesDataModal from './service-providers/modals/StandbyTimesDataModal';
import SPRentalCarPricingDataModal from './service-providers/modals/SPRentalCarPricingDataModal';
import SPRentalCarAdditionalServicesDataModal from './service-providers/modals/SPRentalCarAdditionalServicesDataModal';
import DeleteSPRentalCarPricingConfirmationModal from './service-providers/modals/DeleteSPRentalCarPricingConfirmationModal';
import ContractPartnerStatusUpdateModal from './contract-partners/modals/ContractPartnerStatusUpdateModal';
import EditAssignmentChannelsModal from './contract-partners/modals/EditAssignmentChannelsModal';
import CreateTemporaryRestrictionModal from './contract-partners/modals/CreateTemporaryRestrictionModal';
import CreateTemporaryRestrictionSuccessModal from './contract-partners/modals/CreateTemporaryRestrictionSuccessModal';
import EditTemporaryRestrictionModal from './contract-partners/modals/EditTemporaryRestrictionModal';
import CreateQualityReportModal from './contract-partners/modals/CreateQualityReportModal';
import QualityManagementNoteQualificationModal from './contract-partners/modals/QualityManagementNoteQualificationModal';
import ContractPartnerContactDataModal from './contract-partners/modals/ContractPartnerContactDataModal';
import DeleteCPContactDataConfirmationModal from './contract-partners/modals/DeleteCPContactDataConfirmationModal';
import INARulesEditModal from './ina-rules/modals/INARulesEditModal';

/*
* Modal configuration
*/
export default [
    {
        id: modalIds.MODAL_ID,
        component: NotFoundScreen,
        hasBackdrop: true,
    },
    {
        id: modalIds.USER_DETAILS,
        component: UserDetailsModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.FETCH_USER_DATA_FAILED_WARNING,
        component: FetchUserDataFailedWarningModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CREATE_COMMISSIONER,
        component: CreateCommissionerModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.DEACTIVATE_SP_CONFIRMATION,
        component: DeactivateSPConfirmationModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.STANDBY_TIMES_DATA_MODAL,
        component: StandbyTimesDataModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SP_RENTAL_CAR_PRICING_DATA,
        component: SPRentalCarPricingDataModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SP_RENTAL_CAR_ADDITIONAL_SERVICE_PRICING_DATA,
        component: SPRentalCarAdditionalServicesDataModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.DELETE_SP_PRICING_DATA_CONFIRMATION,
        component: DeleteSPRentalCarPricingConfirmationModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_STATUS_UPDATE,
        component: ContractPartnerStatusUpdateModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_ASSIGNMENT_CHANNELS_UPDATE,
        component: EditAssignmentChannelsModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_CREATE_TEMPORARY_RESTRICTION,
        component: CreateTemporaryRestrictionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_EDIT_TEMPORARY_RESTRICTION,
        component: EditTemporaryRestrictionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_TEMPORARY_RESTRICTION_SUCCESSFUL,
        component: CreateTemporaryRestrictionSuccessModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_CREATE_QUALITY_REPORT,
        component: CreateQualityReportModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.QM_NOTE_QUALIFICATION,
        component: QualityManagementNoteQualificationModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CONTRACT_PARTNER_CONTACT_DATA,
        component: ContractPartnerContactDataModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.DELETE_CP_CONTACT_DATA_CONFIRMATION,
        component: DeleteCPContactDataConfirmationModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.INA_RULES_EDIT_MODAL,
        component: INARulesEditModal,
        hasBackdrop: true,
    },
];
