import React, {Fragment, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {camelCase, snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import {Form, DateField, SelectField, Option} from '@ace-de/ui-components/form';
import {calendarIcon, resetIcon, InteractiveIcon, ratingNegativeIcon, ratingPositiveIcon, findCaseIcon, Icon, downloadIcon} from '@ace-de/ui-components/icons';
import {NoResultsBlock, Panel, useStyles, Pill} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {ButtonPrimary} from '@ace-de/ui-components/buttons';
import {apmQualityManagementNegativeFeedbackCategoryTypes, apmQualityManagementPositiveFeedbackCategoryTypes, apmGenericQMFeedbackCategoryTypes, apmQualityManagementFeedbackStatusTypes, efRejectionReasonTypes, apmTemporaryRestrictionCategoryTypes} from '@ace-de/eua-entity-types';
import contractPartnerScreenTabs from './contractPartnerScreenTabs';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';
import * as contractPartnersSelectors from './contractPartnerSelectors';
import routePaths from '../routePaths';
import config from '../config';
import formatECSServiceCaseScreenURL from '../utils/formatECSServiceCaseScreenURL';

const initialFormValues = {
    createdAfter: moment().subtract(config.DEFAULT_TIME_PERIOD_IN_MONTHS, 'months'),
    createdBefore: moment(),
    categories: ['ALL'],
};

const dateSortingOptions = {
    ASC: 'ASC',
    DESC: 'DESC',
};

const formTypes = {
    FILTER: 'FILTER',
    SORT: 'SORT',
};

const getValuesFromQueryString = (queryString, formType = formTypes.FILTER) => {
    const queryParams = new URLSearchParams(queryString);
    if (formType === formTypes.SORT) {
        return {
            sort: queryParams.get('sort') || `createdAt,${dateSortingOptions.DESC}`,
        };
    }
    return {
        createdAfter: queryParams.get('createdAfter')
            || moment().subtract(config.DEFAULT_TIME_PERIOD_IN_MONTHS, 'months'),
        createdBefore: queryParams.get('createdBefore')
            || moment(),
        categories: queryParams.getAll('categories') || ['ALL'],
    };
};

const ContractPartnerQualityManagementTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_quality_management_tab');
    const {qualityManagementFeedbacksSearchResults, history, contractPartner} = props;
    const {initiateCreateCPQualityReport, initiateCreateQMNoteQualificationFlow} = props;
    const {initiateDownloadQMFeedback} = props;
    const [filterFormData, setFilterFormData] = useState(history.location.search
        ? getValuesFromQueryString(history.location.search) : initialFormValues);
    const resetRef = useRef(false);
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);

    const [sortFormData, setSortFormData] = useState(history.location.search
        ? getValuesFromQueryString(history.location.search, formTypes.SORT) : {
            sort: `createdAt,${dateSortingOptions.DESC}`,
        });

    const createQualityManagementFeedback = () => {
        initiateCreateCPQualityReport();
    };

    const formatQueryParams = formData => {
        const apiQueryParams = new URLSearchParams();
        apiQueryParams.append('activeTab', contractPartnerScreenTabs.QUALITY_MANAGEMENT);

        Object.keys(formData).forEach(formField => {
            if (['categories'].includes(formField)) {
                formData[formField].forEach(fieldValue => {
                    if (fieldValue !== 'ALL') {
                        apiQueryParams.append(`${formField}`, fieldValue);
                    }
                });
                return;
            }

            if (formField === 'createdAfter' && formData.createdAfter && moment(formData.createdAfter).isValid()) {
                apiQueryParams.append('createdAfter', new Date(formData['createdAfter']).toISOString());
                setStartDateError(false);
                return;
            }
            if (formField === 'createdBefore' && formData.createdBefore && moment(formData.createdBefore).isValid()) {
                const date = new Date(formData['createdBefore']);
                const endDate = moment(date.setHours(23, 59, 59, 59)).format();
                apiQueryParams.append('createdBefore', endDate);
                setEndDateError(false);
                return;
            }

            if (formData[formField] !== undefined && formData[formField] !== '') {
                apiQueryParams.append(`${formField}`, formData[formField]);
            }

            if (formData.createdAfter === '') setStartDateError(true);
            if (formData.createdBefore === '') setEndDateError(true);
        });
        return apiQueryParams;
    };


    const handleOnChange = formValues => {
        if (resetRef.current) {
            resetRef.current = false;
            return;
        }
        setFilterFormData({
            ...initialFormValues,
            ...formValues,
        });

        const apiQueryParams = formValues ? formatQueryParams({
            ...formValues,
            ...sortFormData,
        }) : null;

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';

        history.replace(resolveRoute(
            routePaths.CONTRACT_PARTNER,
            {contractPartnerId: contractPartner.id},
            {search: queryParamsString},
        ));
    };

    const handleResetFilter = () => {
        resetRef.current = true;
        setFilterFormData({
            ...initialFormValues,
        });
        const queryParams = new URLSearchParams(history.location.search);
        const apiQueryParams = formatQueryParams(sortFormData);
        const queryParamsString = queryParams.get('sort') ? apiQueryParams.toString() : '';

        history.replace(resolveRoute(
            routePaths.CONTRACT_PARTNER,
            {contractPartnerId: contractPartner.id},
            {search: queryParamsString},
        ));
    };

    const handleDownloadTable = () => {
        const apiQueryParams = formatQueryParams({
            ...filterFormData,
            contractPartnerId: contractPartner.id,
        });
        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';

        initiateDownloadQMFeedback({
            url: config.ACE_APM_QUALITY_MANAGEMENT_FEEDBACKS_EXPORT_ENDPOINT + `?${queryParamsString}`,
            fileName: `${translateTab('file.name')}.xlsx`,
        });
    };

    const handleOnSort = formValues => {
        setSortFormData({
            ...formValues,
        });

        const apiQueryParams = formValues ? formatQueryParams({
            ...filterFormData,
            ...formValues,
        }) : null;

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';

        history.replace(resolveRoute(
            routePaths.CONTRACT_PARTNER,
            {contractPartnerId: contractPartner.id},
            {search: queryParamsString},
        ));
    };

    const formatCategoryOptionLabel = category => {
        if (!category) return '';
        // NOTE: we are not making the difference between efRejectionReasonTypes.OTHER
        // and apmTemporaryRestrictionCategoryTypes.OTHER when filtering,
        // so we should NOT append the prefix 'TE:' or 'Abgelehnt'
        if (category === efRejectionReasonTypes.OTHER) {
            return translate(`global.quality_management_feedback_category.${snakeCase(category)}`);
        }

        if (Object.values(efRejectionReasonTypes).includes(category)) {
            return translate('global.quality_management_feedback_channel.rejected') + ' '
                + translate(`global.quality_management_feedback_category.${snakeCase(category)}`);
        }

        if (Object.values(apmTemporaryRestrictionCategoryTypes).includes(category)) {
            return translate('global.quality_management_feedback_channel.temporary_restriction') + ' '
                + translate(`global.quality_management_feedback_category.${snakeCase(category)}`);
        }

        return translate(`global.quality_management_feedback_category.${snakeCase(category)}`);
    };

    return (
        <Fragment>
            <Panel
                title={translateTab('panel_header.notifications')}
                contentClassName={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--align-space-between',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-flex-end',
                    ])}
                >
                    <div className={cx('global!ace-u-flex--basis-60')}>
                        <Form name="cpQualityManagementFeedbackSearchForm" onChange={handleOnChange}>
                            <div className={cx('global!ace-u-grid')}>
                                <div className={cx('global!ace-u-grid-column--span-3')}>
                                    <DateField
                                        label={translateTab('input_label.start_date')}
                                        name="createdAfter"
                                        placeholder={translateTab('input_placeholder.start_date')}
                                        className={cx(['global!ace-u-width--full', 'global!ace-u-margin--top-4'])}
                                        icon={calendarIcon}
                                        value={filterFormData.createdAfter}
                                        maxDate={moment(filterFormData.createdBefore).format()}
                                        errors={startDateError
                                            ? [translateTab('start_date_error_message.please_select_start_date')]
                                            : []}
                                    />
                                </div>
                                <div className={cx('global!ace-u-grid-column--span-3')}>
                                    <DateField
                                        label={translateTab('input_label.end_date')}
                                        name="createdBefore"
                                        placeholder={translateTab('input_placeholder.end_date')}
                                        className={cx(['global!ace-u-width--full', 'global!ace-u-margin--top-4'])}
                                        icon={calendarIcon}
                                        value={filterFormData.createdBefore}
                                        minDate={moment(filterFormData.createdAfter).format()}
                                        errors={endDateError
                                            ? [translateTab('end_date_error_message.please_select_end_date')]
                                            : []}
                                    />
                                </div>
                                <div className={cx('global!ace-u-grid-column--span-3')}>
                                    <SelectField
                                        name="categories"
                                        label={translateTab('input_label.category')}
                                        className={cx(['global!ace-u-width--full', 'global!ace-u-margin--top-8'])}
                                        isMultipleChoice={true}
                                        placeholder={translateTab('input_placeholder.all')}
                                        value={filterFormData.categories}
                                    >
                                        {Object.keys(apmQualityManagementPositiveFeedbackCategoryTypes)
                                            .concat(Object.keys(apmQualityManagementNegativeFeedbackCategoryTypes))
                                            .concat(Object.keys(apmGenericQMFeedbackCategoryTypes))
                                            .map(category => (
                                                <Option
                                                    key={category}
                                                    value={category}
                                                    name={`qualityFeedbackCategoryOption${camelCase(category)}`}
                                                >
                                                    {formatCategoryOptionLabel(category)}
                                                </Option>
                                            ))}
                                    </SelectField>
                                </div>
                                <div
                                    className={cx(['global!ace-u-grid-column--span-3', 'global!ace-u-margin--top-48'])}
                                >
                                    <InteractiveIcon
                                        icon={resetIcon}
                                        className={cx([
                                            'ace-c-interactive-icon--reverse',
                                            'ace-c-interactive-icon--highlight',
                                            'ace-c-interactive-icon--primary',
                                        ])}
                                        onClick={handleResetFilter}
                                    >
                                        {translateTab('interactive_icon_label.reset_filter')}
                                    </InteractiveIcon>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex--basis-20',
                            'global!ace-u-margin--0-24',
                        ])}
                    >
                        <Form name="cpQualityManagementFeedbackSortingForm" onChange={handleOnSort}>
                            <SelectField
                                name="sort"
                                label={translateTab('input_label.sort')}
                                className={cx(['global!ace-u-width--full', 'global!ace-u-margin--top-8'])}
                                value={sortFormData.sort}
                            >
                                {Object.keys(dateSortingOptions)
                                    .map(sortingOption => (
                                        <Option
                                            key={sortingOption}
                                            value={`createdAt,${sortingOption}`}
                                            name={`dateSortingOption${camelCase(sortingOption)}`}
                                        >
                                            {translateTab(`input_option.${snakeCase(sortingOption)}`)}
                                        </Option>
                                    ))}
                            </SelectField>
                        </Form>
                    </div>
                    <div className={cx('global!ace-u-flex--basis-10')}>
                        <p className={cx('global!ace-u-typography--variant-caption')}>
                            {translateTab('input_label.period_statistics')}
                        </p>
                        <div
                            className={cx([
                                'global!ace-u-margin--top-8',
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-flex-start',
                                'global!ace-u-flex--align-center',
                            ])}
                        >
                            <Pill type="pending" className={cx('global!ace-u-padding--24-32')}>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-flex--justify-space-between',
                                    ])}
                                >
                                    <p
                                        className={cx([
                                            'global!ace-u-margin--right-16',
                                            'global!ace-u-typography--variant-h3',
                                            'global!ace-u-typography--color-warning',
                                        ])}
                                    >
                                        {qualityManagementFeedbacksSearchResults.filter(result => {
                                            return !result.positiveFeedback && [
                                                apmQualityManagementFeedbackStatusTypes.JUSTIFIED,
                                                apmQualityManagementFeedbackStatusTypes.OPEN,
                                            ].includes(result.justification);
                                        }).length}
                                    </p>
                                    <Icon icon={ratingNegativeIcon} />
                                </div>
                            </Pill>
                            <Pill
                                type="pending"
                                className={cx(['global!ace-u-padding--24-32', 'global!ace-u-margin--left-16'])}
                            >
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-flex--justify-space-between',
                                    ])}
                                >
                                    <p
                                        className={cx([
                                            'global!ace-u-margin--right-16',
                                            'global!ace-u-typography--variant-h3',
                                            'global!ace-u-typography--color-success',
                                        ])}
                                    >
                                        {qualityManagementFeedbacksSearchResults.filter(result => {
                                            return !!result.positiveFeedback && [
                                                apmQualityManagementFeedbackStatusTypes.JUSTIFIED,
                                                apmQualityManagementFeedbackStatusTypes.OPEN,
                                            ].includes(result.justification);
                                        }).length}
                                    </p>
                                    <Icon icon={ratingPositiveIcon} />
                                </div>
                            </Pill>
                        </div>
                    </div>
                    <ButtonPrimary
                        onClick={createQualityManagementFeedback}
                        className={cx('global!ace-u-margin--left-24')}
                    >
                        {translateTab('button_label.create_note')}
                    </ButtonPrimary>
                </div>
            </Panel>
            <Panel className={cx('ace-c-panel--full-bleed')}>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <h3
                        className={cx([
                            'global!ace-u-margin--left-32',
                            'global!ace-u-typography--variant-h3',
                            'global!ace-u-flex--basis-60',
                        ])}
                    >
                        {translateTab('panel_header.quality_notifications')}
                    </h3>
                    <InteractiveIcon
                        icon={downloadIcon}
                        className={cx([
                            'ace-c-interactive-icon--reverse',
                            'ace-c-interactive-icon--highlight',
                            'global!ace-u-margin--32',
                        ])}
                        onClick={handleDownloadTable}
                        isDisabled={!qualityManagementFeedbacksSearchResults.length}
                    >
                        {translateTab('interactive_icon_label.download')}
                    </InteractiveIcon>
                </div>
                <Table>
                    {qualityManagementFeedbacksSearchResults.length === 0 && (
                        <TableCaption>
                            <NoResultsBlock
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl')}
                                        icon={findCaseIcon}
                                    />
                                )}
                                message={translateTab('no_results.message')}
                            />
                        </TableCaption>
                    )}
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {translateTab('table_header.entry')}
                            </TableCell>
                            <TableCell colSpan={3}>
                                {translateTab('table_header.category')}
                            </TableCell>
                            <TableCell colSpan={3}>
                                {translateTab('table_header.description')}
                            </TableCell>
                            <TableCell>
                                {translateTab('table_header.case_number')}
                            </TableCell>
                            <TableCell>
                                {translateTab('table_header.created_by')}
                            </TableCell>
                            <TableCell>
                                {translateTab('table_header.qualification')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {qualityManagementFeedbacksSearchResults.map((feedback, index) => (
                            <TableRow
                                key={index}
                                className={cx([
                                    {'global!ace-u-typography--variant-body-bold': feedback.justification === apmQualityManagementFeedbackStatusTypes.OPEN},
                                    {'global!ace-u-typography--color-disabled': feedback.justification === apmQualityManagementFeedbackStatusTypes.NOT_JUSTIFIED},
                                ])}
                                onClick={() => initiateCreateQMNoteQualificationFlow({feedback})}
                            >
                                <TableCell>
                                    {moment(feedback.createdAt).format('DD.MM.YYYY HH:mm')}
                                </TableCell>
                                <TableCell colSpan={3}>
                                    <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--align-center'])}>
                                        <Icon
                                            className={cx(['global!ace-u-margin--right-16', 'global!ace-u-min-width--24'])}
                                            icon={feedback.positiveFeedback ? ratingPositiveIcon : ratingNegativeIcon}
                                        />
                                        {feedback.categories.map(category => (
                                            (feedback.qualityManagementFeedbackChannel
                                                ? translate(`global.quality_management_feedback_channel.${snakeCase(feedback.qualityManagementFeedbackChannel)}`) + ' '
                                                : ''
                                            ) + translate(`global.quality_management_feedback_category.${snakeCase(category)}`)
                                        )).join(', ')}
                                    </div>
                                </TableCell>
                                <TableCell colSpan={3}>
                                    {feedback.description}
                                </TableCell>
                                <TableCell>
                                    {feedback.caseId ? (
                                        <a
                                            href={formatECSServiceCaseScreenURL(feedback.caseId)}
                                            className={cx([
                                                'global!ace-u-typography--variant-body',
                                            ])}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {feedback.caseId}
                                        </a>
                                    ) : ''}
                                </TableCell>
                                <TableCell>
                                    {feedback.createdByUser}
                                </TableCell>
                                <TableCell>
                                    {feedback.justification !== apmQualityManagementFeedbackStatusTypes.OPEN
                                        ? feedback.justification === apmQualityManagementFeedbackStatusTypes.JUSTIFIED
                                            ? translateTab('input_option.justified') : translateTab('input_option.unjustified')
                                        : translateTab('input_option.open')}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Panel>
        </Fragment>
    );
};

ContractPartnerQualityManagementTab.propTypes = {
    history: PropTypes.object.isRequired,
    initiateCreateCPQualityReport: PropTypes.func.isRequired,
    initiateCreateQMNoteQualificationFlow: PropTypes.func.isRequired,
    initiateDownloadQMFeedback: PropTypes.func.isRequired,
    contractPartner: PropTypes.object,
    qualityManagementFeedbacksSearchResults: PropTypes.array,
};

ContractPartnerQualityManagementTab.defaultProps = {
    contractPartner: null,
    qualityManagementFeedbacksSearchResults: [],
};

const mapStateToProps = (state, props) => {
    const getContractPartner = contractPartnersSelectors.createContractPartnerSelector();
    return {
        contractPartner: getContractPartner(state, props),
        qualityManagementFeedbacksSearchResults: contractPartnersSelectors.getQMFeedbackSearchResults(state),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCreateCPQualityReport: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CREATE_QUALITY_REPORT_FLOW,
        payload,
    }),
    initiateCreateQMNoteQualificationFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CREATE_QM_NOTE_QUALIFICATION_FLOW,
        payload,
    }),
    initiateDownloadQMFeedback: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_QUALITY_MANAGEMENT_FEEDBACKS_DOWNLOAD_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerQualityManagementTab));
