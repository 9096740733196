import {select, fork, take, put} from 'redux-saga/effects';
import {apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceProviderActionTypes from '../serviceProviderActionTypes';

/**
 * Search service providers
 */
const searchServiceProviders = function* searchServiceProviders({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {searchQueryParams} = payload;

    yield fork(
        fetchRequest,
        serviceProviderActionTypes.SEARCH_SERVICE_PROVIDERS_REQUEST,
        partnerManagementService.getACEPartners, {
            searchQueryParams,
            partnerType: apmACEPartnerTypes.SERVICE_PROVIDER,
        },
    );

    const searchServiceProvidersResponseAction = yield take([
        serviceProviderActionTypes.SEARCH_SERVICE_PROVIDERS_REQUEST_SUCCEEDED,
        serviceProviderActionTypes.SEARCH_SERVICE_PROVIDERS_REQUEST_FAILED,
    ]);

    if (!searchServiceProvidersResponseAction.error) {
        const {response} = searchServiceProvidersResponseAction.payload;
        const {
            acePartnerDTOs: serviceProviderSearchResults,
            totalCount: serviceProviderSearchResultsCount,
        } = response;

        yield put({
            type: serviceProviderActionTypes.STORE_SERVICE_PROVIDER_SEARCH_RESULTS,
            payload: {serviceProviderSearchResults, serviceProviderSearchResultsCount},
        });
    }
};

export default searchServiceProviders;
