import {put} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import config from '../../config';
import routePaths from '../../routePaths';
import modalIds from '../../modalIds';
import * as userProfileActionTypes from '../userProfileActionTypes';
import acAccessControl from '../../acAccessControl';
import {acFeatureActions, acFeatures} from '../../application/acFeatures';

const loadUserSearchResults = function* loadUserSearchResults({payload}) {
    const userHasLEAAdminRole = acAccessControl.grantFeatureAccess(
        acFeatures.USER_ROLES_LEA_CLIENT,
        acFeatureActions.UPDATE,
    );
    const {location} = payload;
    const searchQueryParams = new URLSearchParams(location.search);
    if (!searchQueryParams.toString().includes('page')) searchQueryParams.append('page', config.INITIAL_PAGE_INDEX);
    if (userHasLEAAdminRole) searchQueryParams.append('userRole', 'AUDITOR');
    searchQueryParams.append('size', `${config.DEFAULT_PAGE_SIZE}`);

    const userManagementScreenMatch = matchPath(location.pathname, {
        path: routePaths.USER_MANAGEMENT,
        exact: true,
    });

    if (!userManagementScreenMatch) return;
    // do not reload user search results when agent opens user details modal
    if (location && location.state?.isModalOpen && location.state?.modalId === modalIds.USER_DETAILS) return;

    yield put({
        type: userProfileActionTypes.SEARCH_USERS,
        payload: {searchQueryParams},
    });
};

export default loadUserSearchResults;
