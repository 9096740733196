import {take, fork, select, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {resolveRoute} from '@computerrock/formation-router';
import {User, eupUserRoleTypes, clientTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as userProfileActionTypes from '../userProfileActionTypes';
import * as selectors from '../userProfileSelectors';
import modalIds from '../../modalIds';
import routePaths from '../../routePaths';
import acAccessControl from '../../acAccessControl';

const editUserDetailFlow = function* editUserDetailFlow() {
    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userProfileService');
    const ecsFlowService = serviceManager.loadService('ecsFlowService');

    while (true) {
        const {payload} = yield take(userProfileActionTypes.INITIATE_EDIT_USER_PROFILE_FLOW);
        const currentUser = yield select(selectors.getUser);
        const {history, queryParamsString, pathParams} = payload;
        const currentUserRoles = currentUser?.clientRoles?.find(clientRole => {
            return clientRole.client === clientTypes.AC_CLIENT;
        }).userRoles || [];
        const shouldFetchLockedCases = currentUserRoles ? currentUserRoles.includes(eupUserRoleTypes.ECS_ADMIN)
            || currentUserRoles.includes(eupUserRoleTypes.SUPER_ADMIN) : false;

        yield fork(
            fetchRequest,
            userProfileActionTypes.FETCH_USER_DETAILS_REQUEST,
            userProfileService.getUserDetails,
            {userId: pathParams.userId},
        );

        const fetchUserDetailsResponseAction = yield take([
            userProfileActionTypes.FETCH_USER_DETAILS_REQUEST_SUCCEEDED,
            userProfileActionTypes.FETCH_USER_DETAILS_REQUEST_FAILED,
        ]);

        if (shouldFetchLockedCases) {
            yield fork(
                fetchRequest,
                userProfileActionTypes.FETCH_LOCKED_SERVICE_CASES,
                ecsFlowService.getLockedServiceCases,
                {userId: pathParams.userId},
            );

            const fetchLockedServiceCasesResponseAction = yield take([
                userProfileActionTypes.FETCH_LOCKED_SERVICE_CASES_REQUEST_SUCCEEDED,
                userProfileActionTypes.FETCH_LOCKED_SERVICE_CASES_REQUEST_FAILED,
            ]);

            if (!fetchLockedServiceCasesResponseAction.error) {
                const {lockedServiceCaseResults} = fetchLockedServiceCasesResponseAction.payload.response;

                yield put({
                    type: userProfileActionTypes.STORE_LOCKED_SERVICE_CASE_RESULTS,
                    payload: {lockedServiceCaseResults, userId: pathParams.userId},
                });
            }
        }

        if (!fetchUserDetailsResponseAction.error) {
            const {userDTO} = fetchUserDetailsResponseAction.payload.response;

            yield put({
                type: userProfileActionTypes.STORE_USER_DETAILS,
                payload: {userDTO},
            });

            yield* openModal(modalIds.USER_DETAILS, {mode: 'edit'});

            const responseAction = yield take([
                userProfileActionTypes.CONFIRM_EDIT_USER_PROFILE,
                userProfileActionTypes.DECLINE_EDIT_USER_PROFILE,
            ]);

            if (responseAction.type === userProfileActionTypes.CONFIRM_EDIT_USER_PROFILE) {
                const userData = responseAction.payload;

                yield fork(
                    fetchRequest,
                    userProfileActionTypes.UPDATE_USER_PROFILE_REQUEST,
                    userProfileService.updateUserProfile,
                    {
                        userId: pathParams.userId,
                        userDataPatchDTO: User.objectToPatchDTO(userData),
                    },
                );

                const updateUserDataResponseAction = yield take([
                    userProfileActionTypes.UPDATE_USER_PROFILE_REQUEST_SUCCEEDED,
                    userProfileActionTypes.UPDATE_USER_PROFILE_REQUEST_FAILED,
                ]);

                if (!updateUserDataResponseAction.error) {
                    const {userDTO} = updateUserDataResponseAction.payload.response;

                    yield put({
                        type: userProfileActionTypes.STORE_USER_DETAILS,
                        payload: {userDTO},
                    });

                    yield* closeModal(modalIds.USER_DETAILS, {mode: 'edit'});

                    if (pathParams.userId === currentUser.id) {
                        yield put(acAccessControl.cleanUserPrivileges);
                        history.push(routePaths.ROOT);
                        continue;
                    }

                    history.replace(resolveRoute(routePaths.USER_MANAGEMENT, {}, {search: queryParamsString || ''}));
                }
            }

            yield* closeModal(modalIds.USER_DETAILS, {mode: 'edit'});
            history.replace(resolveRoute(routePaths.USER_MANAGEMENT, {}, {search: queryParamsString || ''}));
        } else {
            yield* openModal(modalIds.FETCH_USER_DATA_FAILED_WARNING);
        }
    }
};

export default editUserDetailFlow;
