import {produce, current} from 'immer';
import {ACEPartner, TemporaryRestriction, QualityManagementFeedback, EmergencyContact} from '@ace-de/eua-entity-types';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';
import createNewServiceCase from './createNewServiceCase';

const initialState = {
    contractPartners: {},
    contractPartnerSearchResults: [],
    contractPartnerSearchResultsCount: 0,
    contractPartnerLocationRecommendations: [],
    cpQMFeedbacksSearchResults: [],
    cpQMFeedbacksSearchCount: 0,
    serviceCaseSearchResults: [],
    serviceCaseSearchResultsCount: 0,
    isQMQualificationNoteForwarded: false,
    commissioners: {},
};

/**
 * Contract partner reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const contractPartnerReducer = produce((draft, action) => {
    switch (action.type) {
        case contractPartnerActionTypes.STORE_CONTRACT_PARTNER_SEARCH_RESULTS: {
            const {contractPartnerSearchResults, contractPartnerSearchResultsCount} = action.payload;
            draft.contractPartnerSearchResults = contractPartnerSearchResults.map(contractPartnerDTO => {
                return new ACEPartner().fromDTO(contractPartnerDTO);
            });
            draft.contractPartnerSearchResultsCount = typeof contractPartnerSearchResultsCount === 'number'
                ? contractPartnerSearchResultsCount : 0;
            break;
        }

        case contractPartnerActionTypes.STORE_CONTRACT_PARTNERS: {
            const {contractPartnerDTOs} = action.payload;
            contractPartnerDTOs.forEach(contractPartnerDTO => {
                const contractPartner = draft.contractPartners[contractPartnerDTO.id];
                if (!contractPartner) {
                    const newContractPartner = new ACEPartner().fromDTO(contractPartnerDTO);
                    draft.contractPartners[newContractPartner.id] = newContractPartner;
                    return;
                }
                draft.contractPartners[contractPartnerDTO.id] = contractPartner.fromDTO(contractPartnerDTO);
            });
            break;
        }

        case contractPartnerActionTypes.SET_CP_LOCATION_RECOMMENDATIONS: {
            const {arcGISLocationCandidateDTOs} = action.payload;
            draft.contractPartnerLocationRecommendations = arcGISLocationCandidateDTOs;
            break;
        }

        case contractPartnerActionTypes.STORE_CP_TEMPORARY_RESTRICTIONS: {
            const {contractPartnerId, temporaryRestrictionDTOs} = action.payload;
            let draftContractPartner = current(draft.contractPartners[contractPartnerId]);

            if (draftContractPartner) {
                temporaryRestrictionDTOs.forEach(temporaryRestrictionDTO => {
                    const newTemporaryRestriction = new TemporaryRestriction().fromDTO(temporaryRestrictionDTO);
                    draftContractPartner = draftContractPartner
                        .setTemporaryRestriction(newTemporaryRestriction.id, newTemporaryRestriction);
                });
                draft.contractPartners[contractPartnerId] = draftContractPartner;
            }
            break;
        }

        case contractPartnerActionTypes.STORE_CP_QUALITY_MANAGEMENT_FEEDBACKS_SEARCH_RESULTS: {
            const {qualityManagementFeedbacksSearchResults, qualityManagementFeedbacksSearchCount} = action.payload;
            draft.cpQMFeedbacksSearchResults = qualityManagementFeedbacksSearchResults.map(feedback => (
                new QualityManagementFeedback().fromDTO(feedback)
            ));
            draft.cpQMFeedbacksSearchCount = typeof qualityManagementFeedbacksSearchCount === 'number'
                ? qualityManagementFeedbacksSearchCount : 0;
            break;
        }

        case contractPartnerActionTypes.STORE_SERVICE_CASE_SEARCH_RESULTS: {
            const {serviceCaseSearchResults, serviceCaseSearchResultsCount} = action.payload;
            draft.serviceCaseSearchResults = serviceCaseSearchResults.map(serviceCaseDTO => {
                return createNewServiceCase(serviceCaseDTO.caseType).fromDTO(serviceCaseDTO);
            });
            draft.serviceCaseSearchResultsCount = serviceCaseSearchResultsCount;
            break;
        }

        case contractPartnerActionTypes.STORE_QM_QUALIFICATION_NOTE_FORWARDING_STATUS: {
            const {isQMQualificationNoteForwarded} = action.payload;
            draft.isQMQualificationNoteForwarded = !!isQMQualificationNoteForwarded;
            break;
        }

        case contractPartnerActionTypes.STORE_CP_CONTACT_DATA: {
            const {contractPartnerId, contactDataDTO} = action.payload;
            const contractPartner = draft.contractPartners[contractPartnerId];
            if (!contractPartner || !contactDataDTO) return;
            const index = contractPartner.emergencyContacts.findIndex(data => data.id === contactDataDTO.id);
            if (index >= 0) {
                contractPartner.emergencyContacts[index] = new EmergencyContact().fromDTO(contactDataDTO);
                break;
            }
            contractPartner.emergencyContacts.push(new EmergencyContact().fromDTO(contactDataDTO));
            break;
        }

        case contractPartnerActionTypes.REMOVE_CP_CONTACT_DATA: {
            const {contactDataId, contractPartnerId} = action.payload;
            const contractPartner = draft.contractPartners[contractPartnerId];
            const contactDataIndex = contractPartner.emergencyContacts?.findIndex(contact => {
                return contact.id === contactDataId;
            });
            if (contactDataIndex >= 0 && contractPartner.emergencyContacts[contactDataIndex]) {
                contractPartner.emergencyContacts[contactDataIndex].deleted = true;
            }
            break;
        }

        case contractPartnerActionTypes.STORE_ACE_COMMISSIONERS: {
            const {commissionerDTOs} = action.payload;
            commissionerDTOs.forEach(commissionerDTO => {
                const commissioner = draft.commissioners[commissionerDTO.id];
                if (!commissioner) {
                    const newCommissioner = new ACEPartner().fromDTO(commissionerDTO);
                    draft.commissioners[newCommissioner.id] = newCommissioner;
                    return;
                }
                draft.commissioners[commissionerDTO.id] = commissioner.fromDTO(commissionerDTO);
            });
            break;
        }

        default:
            // no-op
    }
}, initialState);

export default contractPartnerReducer;
